import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { Link } from 'react-router-dom';
import { IOrchestrationRow } from '../../interfaces/dashboard/orchestration-row.interface';
import { IModuleUsedByRow } from 'interfaces/modules/module.interface';
import { VIEW_OPTIONS } from 'pages/RunOrchestration/RunOrchestrations.constants';
import {
  MODULE_DATA,
  MODULE_DISPLAY_PARAM,
  MODULE_NAME_PARAM,
} from 'pages/ManageModules/ManageModules.constants';
import { setSidebarNavigated } from 'app/modal/ModalSlice';
import { SPINE_MENU_ITEMS } from 'utils/common-constants';
import { useAppDispatch } from 'app/hooks';
import { useEffect } from 'react';

type SupportedTypes = IOrchestrationRow | IModuleUsedByRow;

const CellLink = (props: any) => {
  const rowData: SupportedTypes = props.data;
  const colId = props.column?.colId;
  const text = rowData?.[colId as keyof SupportedTypes] ?? '';
  const dispatch = useAppDispatch();
  let link = '';
  if (rowData && ('workflowId' as keyof IOrchestrationRow) in rowData) {
    link = `orchestration/${text}`;
  } else if (('slave' as keyof IModuleUsedByRow) in rowData) {
    const moduleUsedByRow = rowData as IModuleUsedByRow;
    link = `/run-orchestrations?${MODULE_DISPLAY_PARAM}=${VIEW_OPTIONS.COLUMN_VIEW}&${MODULE_NAME_PARAM}=${text}&${MODULE_DATA}=${moduleUsedByRow.slave}-${moduleUsedByRow.moduleId}`;
  }

  const handleClick = () => {
    if (link.includes('/run-orchestrations')) {
      dispatch(setSidebarNavigated(SPINE_MENU_ITEMS.RUN_ORCHESTRATION));
    }
  };
  return (
    <CsbErrorBoundary>
      <div className="cell-link" data-testid="CellLink">
        <Link to={link} replace onClick={handleClick}>
          {text}
        </Link>
      </div>
    </CsbErrorBoundary>
  );
};

export default CellLink;
