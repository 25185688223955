import { Tooltip, TooltipBody } from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useEffect, useRef, useState } from 'react';
import style from './CellWithTooltip.module.scss';
import {
  IModuleInstancesRow,
  IModuleParametersRow,
} from 'interfaces/modules/module.interface';
import useWkTooltipOutsideComponent from 'utils/hooks/wk-tooltips/useWkTooltipOutsideComponent';

type SupportedTypes = IModuleParametersRow | IModuleInstancesRow;

const CellWithTooltip = (props: any) => {
  const rowData: SupportedTypes = props.data;
  const colId = props.column?.colId;
  const text = rowData[(colId as keyof SupportedTypes) ?? ''];
  const [isStatusTooltipOpen, setIsStatusTooltipOpen] =
    useState<boolean>(false);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const spanRef = useRef<HTMLSpanElement>(null);
  const { moveWkTooltipToOutsideElement } = useWkTooltipOutsideComponent(
    `status-${rowData.id}-${colId}-tooltip`
  );

  useEffect(() => {
    if (isStatusTooltipOpen && isTruncated) {
      moveWkTooltipToOutsideElement();
    }
  }, [isStatusTooltipOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (spanRef.current) {
        setIsTruncated(
          spanRef.current.scrollWidth > spanRef.current.clientWidth
        );
      }
    }, 100);
  }, [rowData, spanRef]);

  return (
    <CsbErrorBoundary>
      <div data-testid="CellWithTooltip" className={style['cell-container']}>
        <section
          onMouseOver={() => isTruncated && setIsStatusTooltipOpen(true)}
          onMouseLeave={() => setIsStatusTooltipOpen(false)}
          id={`status-${rowData.id}-${colId}`}
        >
          <span ref={spanRef} className={style['content']}>
            {text}
          </span>
        </section>
        <Tooltip
          position="left"
          targetSelector={`#status-${rowData.id}-${colId}`}
          isOpen={isStatusTooltipOpen}
          id={`status-${rowData.id}-${colId}-tooltip`}
          controlMode="controlled"
        >
          <TooltipBody slot="tooltipBody">
            <span
              data-testid="CellStatusTooltip"
              className={style['tooltip-content']}
            >
              {text}
            </span>
          </TooltipBody>
        </Tooltip>
      </div>
    </CsbErrorBoundary>
  );
};

export default CellWithTooltip;
