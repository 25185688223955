import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  resetUpdatedWorkflow,
  selectSelectedWorkflow,
  selectUpdatedWorkflow,
} from 'app/runOrchestrations/RunOrchestrationsSlice';
import {
  IParamDetail,
  IServerProduct,
  IServerWorkflow,
  IWorkflowStepGroup,
} from 'interfaces/workflow.interface';
import { IWorkflowProduct } from 'interfaces/runOrchestration/workflow-row';
import { useEffect, useState } from 'react';

export const useWorkflowsViews = ({
  initialWorkflows,
}: {
  initialWorkflows: IServerWorkflow[];
}) => {
  const selectedWorkflow = useAppSelector(selectSelectedWorkflow);
  const updatedWorkflow = useAppSelector(selectUpdatedWorkflow);
  const [workflows, setWorkflows] =
    useState<IServerWorkflow[]>(initialWorkflows);
  const dispatch = useAppDispatch();
  const [workflowToLoad, setWorkflowToLoad] = useState<
    IServerWorkflow | undefined
  >(undefined);

  useEffect(() => {
    if (updatedWorkflow !== null) {
      const workflow: IServerWorkflow = updatedWorkflow;
      const updatedWorkflows = workflows?.map((w) =>
        w.workflowId === workflow.workflowId || w.name === workflow.name
          ? workflow
          : w
      ) || [workflow];
      if (updatedWorkflows && updatedWorkflows.length > 0) {
        setWorkflows(updatedWorkflows);
        dispatch(resetUpdatedWorkflow());
      }
    }
  }, [updatedWorkflow]);

  useEffect(() => {
    setWorkflows(initialWorkflows);
  }, [initialWorkflows]);

  useEffect(() => {
    if (selectedWorkflow) {
      const findWorkflow = (workflowId: number, name: string) =>
        workflows?.find(
          (workflow) =>
            workflow.workflowId === workflowId || name === workflow.name
        );
      const name = selectedWorkflow.isProduct
        ? (selectedWorkflow as IWorkflowProduct).parentName
        : selectedWorkflow?.name;

      if (!selectedWorkflow.isProduct) {
        setWorkflowToLoad(findWorkflow(selectedWorkflow.id, name));
      } else {
        const parent = findWorkflow(
          (selectedWorkflow as IWorkflowProduct).parentId,
          name
        );
        if (parent) {
          const productOrchestration = parent?.productGroup?.products?.find(
            (product: IServerProduct) =>
              product.productId ===
                (selectedWorkflow as IWorkflowProduct)?.productId ||
              product.name === (selectedWorkflow as IWorkflowProduct).name
          );
          if (productOrchestration) {
            const newWorkflow = updateWorkflowWithProductParameters(
              parent,
              productOrchestration
            );
            setWorkflowToLoad(newWorkflow);
          }
        }
      }
    }
  }, [selectedWorkflow, workflows]);

  return workflowToLoad;
};

function updateWorkflowWithProductParameters(
  workflow: IServerWorkflow,
  product: IServerProduct
) {
  const newWorkflow = JSON.parse(JSON.stringify(workflow));

  if (newWorkflow.nextStepGroup) {
    traverseSteps(newWorkflow.nextStepGroup, product);
  }
  if (newWorkflow.failbackStepGroup) {
    traverseSteps(newWorkflow.failbackStepGroup, product);
  }
  return newWorkflow;
}

const updateParams = (params: IParamDetail[], product: any) => {
  if (!params) {
    return;
  }
  params.forEach((param: IParamDetail) => {
    const productParam = product?.params.find(
      (p: any) => p.paramId === param.paramId
    );
    if (productParam) {
      param.value = productParam.value;
    }
  });
};

const traverseSteps = (stepData: IWorkflowStepGroup, product: any) => {
  if (!stepData?.steps) {
    return;
  }
  stepData.steps.forEach((step) => {
    if (step.paramGroups) {
      step.paramGroups.forEach((group) => {
        updateParams(group.parameters, product);
      });
    }
    if (step.nestedStepGroup) {
      traverseSteps(step.nestedStepGroup, product);
    }
    if (step.failbackStepGroup) {
      traverseSteps(step.failbackStepGroup, product);
    }
  });
  if (stepData.nextStepGroup) {
    traverseSteps(stepData.nextStepGroup, product);
  }
};
