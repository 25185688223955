import { IOrchestrationSidePanelSteps } from 'interfaces/orchestrationDiagram/orchestration-diagram';
import { IStepInformation } from 'interfaces/orchestration.interface';

export const fullStepsToJobSteps = (
  steps: IOrchestrationSidePanelSteps[]
): IStepInformation[] => {
  const result: IStepInformation[] = steps.map(
    (step: IOrchestrationSidePanelSteps) => ({
      workflowStepId: step.id,
      name: step.name,
      status: step.information?.status as any,
      startTime: step.information?.startTime as any,
      endTime: step.information?.endTime as any,
      jobStepParams: step.stepProperties,
      jobId: step.jobId,
      jobStepId: step.jobStepId,
    })
  );
  return result;
};
