import {
  ColumnApi,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  RowDataUpdatedEvent,
  RowSelectedEvent,
} from 'ag-grid-community';
import { useLazyGetOrchestrationByIdQuery } from 'api/orchestrations/orchestrationApi';
import {
  addOrchestrationRemoveLastOne,
  selectFilterModel,
  selectFilterStatus,
  selectPaginationInfo,
} from 'app/dashboard/DashboardSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import {
  handleFilter,
  handleRowDataUpdated,
  handleRowSelection,
  handleSort,
} from '../utils/eventsHandlers';
import {
  setNameFilterPopupWidth,
  setNameFilterValues,
  setUserFilterValues,
} from '../utils/filterUtils';
import { IJobStartedByUser } from 'interfaces/user.interface';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import {
  IWorkflow,
  IWorkflowProduct,
} from 'interfaces/runOrchestration/workflow-row';
import {
  ORCHESTRATION_FILTERS,
  ORCHESTRATION_STATUSES,
  TABLE_FIELDS,
} from 'utils/common-constants';
import { IUpdateOrchestrationStatus } from 'interfaces/dashboard/dashboard-slice.interface';
import {
  updateJobStatusInTable,
  updateJobStepsInTable,
} from 'dto/jobToTable/jobToTable';
import {
  combineStatus,
  getOrchestrationDuration,
} from 'utils/commonFunctions/CommonFunctions';
import { IStepInformation } from 'interfaces/orchestration.interface';
import { IOrchestrationStatus } from 'interfaces/dashboard/orchestration-status.interface';
import { getOrchestrationById } from 'api/orchestrations/orchestrationsThunk';

export const useDashboardTableEvents = (props: any) => {
  const dispatch = useAppDispatch();
  const gridApiRef = useRef<GridApi | null>(null);
  const gridColumnApiRef = useRef<ColumnApi | null>(null);
  const { activeFilters: activeFiltersState, allUsers } = useAppSelector(
    (state) => state.dashboardContainer
  );
  const filtersState = useAppSelector(selectFilterStatus);
  const paginatorInfo = useAppSelector(selectPaginationInfo);
  const filterModelState = useAppSelector(selectFilterModel);
  const activeFilters = useRef(activeFiltersState);
  const filterModel = useRef(filterModelState);
  const filterInfo: any = useRef(filtersState);
  const openFilterId: any = useRef();
  const filterDomElement: any = useRef(null);

  const usersRef = useUserFilterSetup(allUsers, gridApiRef);

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;

    params.api.sizeColumnsToFit();
  };

  const onRowDataUpdated = (event: RowDataUpdatedEvent) => {
    handleRowDataUpdated(
      event,
      gridColumnApiRef,
      gridApiRef,
      activeFilters,
      props.onGridReadyAdditionalMethods
    );
  };

  const onRowSelected = async (event: RowSelectedEvent) => {
    handleRowSelection(event, getOrchestrationById, dispatch);
  };

  const onGridSizeChanged = (event: GridSizeChangedEvent) => {
    gridApiRef.current?.sizeColumnsToFit();
  };

  const onFilterOpened = (event: any) => {
    filterDomElement.current = document.querySelector(
      '.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child'
    );
    if (filterDomElement?.current) {
      const id = event.column.colId;
      openFilterId.current = id;
      setNameFilterPopupWidth(id);
    }
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    handleFilter(
      event,
      filterModel,
      props.isServerSideFiltering,
      filterInfo.current,
      usersRef,
      dispatch,
      paginatorInfo
    );
  };

  const onSortChanged = (params: any) => {
    const sorted = params?.api?.columnModel?.displayedColumns?.find(
      (column: any) => column.sort != null
    );
    handleSort(
      sorted,
      filterInfo.current,
      dispatch,
      gridColumnApiRef,
      gridApiRef,
      props.page
    );
  };

  const memoizedHandlers = useMemo(
    () => ({
      onGridReady,
      onRowDataUpdated,
      onRowSelected,
      onGridSizeChanged,
      onFilterOpened,
      onFilterChanged,
      onSortChanged,
    }),
    [
      onGridReady,
      onRowDataUpdated,
      onRowSelected,
      onGridSizeChanged,
      onFilterOpened,
      onFilterChanged,
      onSortChanged,
    ]
  );

  return {
    gridApiRef,
    gridColumnApiRef,
    filtersState,
    paginatorInfo,
    filterModelState,
    activeFilters,
    filterModel,
    filterInfo,
    openFilterId,
    filterDomElement,
    ...memoizedHandlers,
  };
};

const useUserFilterSetup = (
  allUsers: IJobStartedByUser[] | undefined,
  gridApi: MutableRefObject<GridApi<any> | null>
) => {
  const usersRef = useRef<IJobStartedByUser[]>([]);

  useEffect(() => {
    if (allUsers?.length) {
      setUserFilterValues(gridApi.current, allUsers);
      usersRef.current = allUsers;
    }
  }, [allUsers, gridApi]);

  return usersRef;
};

export const useOrchestrationStateUpdate = (
  orchestrations: IOrchestrationRow[],
  isMultiPage: MutableRefObject<boolean>,
  isLoadingNewPage: MutableRefObject<boolean>,
  selectedStatus: IOrchestrationStatus
) => {
  const [orchestrationsState, setOrchestrationState] =
    useState<IOrchestrationRow[]>(orchestrations);

  useEffect(() => {
    if (isMultiPage.current === true) {
      setOrchestrationState((prevOrchestrationsState: any) => {
        const uniqueIds = new Set(
          prevOrchestrationsState.map((item: any) => item.id)
        );
        const filteredOrchestrations = orchestrations.filter(
          (item: any) => !uniqueIds.has(item.id)
        );
        return [...prevOrchestrationsState, ...filteredOrchestrations].filter(
          (orchestration: IOrchestrationRow) =>
            selectedStatus.filterId === ORCHESTRATION_FILTERS.ANY ||
            orchestration.status === selectedStatus.filterId
        );
      });
    } else {
      setOrchestrationState(
        orchestrations.filter(
          (orchestration: IOrchestrationRow) =>
            selectedStatus.filterId === ORCHESTRATION_FILTERS.ANY ||
            orchestration.status === selectedStatus.filterId
        )
      );
      isMultiPage.current = true;
    }
    isLoadingNewPage.current = false;
  }, [orchestrations, isMultiPage, isLoadingNewPage]);

  return {
    orchestrationsState,
    setOrchestrationState,
  };
};

export const useWorkflowUpdate = (
  gridApi: MutableRefObject<GridApi<any> | null>,
  filterModel: any,
  workflows: IWorkflow[] | IWorkflowProduct[]
) => {
  useEffect(() => {
    gridApi?.current?.refreshCells({
      force: true,
      columns: [TABLE_FIELDS.name],
    });
    setNameFilterValues(gridApi.current, workflows);
    if (filterModel?.current) {
      gridApi?.current?.setFilterModel(filterModel.current);
    }
  }, [gridApi, filterModel, workflows]);
};
interface IUseHandleOrchestrationEvents {
  sseOrchestrationsEvents: any[];
  setOrchestrationState: any;
  orchestrationsState: IOrchestrationRow[];
  orchestrations: IOrchestrationRow[];
  gridApiRef: MutableRefObject<GridApi<any> | null>;
  dispatch: any;
  modifySseOrchestrationEvents: any;
  selectedStatus: IOrchestrationStatus;
}
export function useHandleOrchestrationEvents({
  sseOrchestrationsEvents,
  setOrchestrationState,
  orchestrationsState,
  orchestrations,
  gridApiRef,
  dispatch,
  modifySseOrchestrationEvents,
  selectedStatus,
}: IUseHandleOrchestrationEvents) {
  useEffect(() => {
    if (sseOrchestrationsEvents?.length > 0) {
      const firstItem = [...sseOrchestrationsEvents].shift();
      if (firstItem) {
        if (firstItem?.type === 'addOrchestration') {
          insertItem(firstItem.data as IOrchestrationRow);
          dispatch(
            modifySseOrchestrationEvents([...sseOrchestrationsEvents].slice(1))
          );
        } else if (
          firstItem.type === 'updateStatus' ||
          firstItem.type === 'updateStep'
        ) {
          updateOrchestration(
            firstItem.data,
            firstItem.type,
            orchestrations,
            selectedStatus
          );
          dispatch(
            modifySseOrchestrationEvents([...sseOrchestrationsEvents].slice(1))
          );
        }
      }
    }
  }, [
    sseOrchestrationsEvents,
    setOrchestrationState,
    orchestrationsState,
    gridApiRef,
    dispatch,
    modifySseOrchestrationEvents,
  ]);

  function insertItem(newItem: IOrchestrationRow) {
    if (newItem) {
      const uniqueIds = new Set(
        orchestrationsState.map((item: any) => item.id)
      );
      if (!uniqueIds.has(newItem.id)) {
        dispatch(addOrchestrationRemoveLastOne(newItem));
      }
    }
  }

  function updateOrchestration(
    updatedOrchestration: IUpdateOrchestrationStatus | IStepInformation,
    type: 'updateStatus' | 'updateStep',
    orchestrations: IOrchestrationRow[],
    selectedStatus: IOrchestrationStatus
  ) {
    const orchestrationId = updatedOrchestration.jobId;
    const findOrchestrationIndex = (orchs: IOrchestrationRow[]) =>
      orchs?.findIndex((orchestration) => orchestration.id === orchestrationId);

    const foundOrchestrationIndex = findOrchestrationIndex(orchestrationsState);
    const foundOrchestrationIndexInGeneralList =
      findOrchestrationIndex(orchestrations);

    if (
      foundOrchestrationIndex === -1 &&
      foundOrchestrationIndexInGeneralList === -1
    ) {
      return orchestrationsState;
    }

    if (foundOrchestrationIndex === -1 && type === 'updateStep') {
      return orchestrationsState;
    }

    const foundOrchestration =
      orchestrationsState[foundOrchestrationIndex] ??
      orchestrations[foundOrchestrationIndexInGeneralList];
    let newOrchestrations: IOrchestrationRow[];
    const isStatusMatch =
      selectedStatus.filterId === combineStatus(updatedOrchestration.status) ||
      selectedStatus.filterId === ORCHESTRATION_FILTERS.ANY;

    if (!isStatusMatch && type !== 'updateStep') {
      if (foundOrchestrationIndex !== -1) {
        newOrchestrations = orchestrationsState.filter(
          (orchestration) => orchestration.id !== orchestrationId
        );
        setOrchestrationState(newOrchestrations);
      }
      return orchestrationsState;
    }

    const updatedItem: IOrchestrationRow = updateOrchestrationItem(
      type,
      foundOrchestration,
      updatedOrchestration
    );

    newOrchestrations = createNewOrchestrations(
      orchestrationsState,
      foundOrchestrationIndex,
      updatedItem
    );

    if (foundOrchestrationIndex === -1 && type !== 'updateStep') {
      newOrchestrations.unshift(updatedItem);
    }

    setOrchestrationState(newOrchestrations);
    const rowNode = gridApiRef.current?.getRowNode(updatedItem.id.toString());
    rowNode?.setData({ ...updatedItem });
    return orchestrationsState;
  }
}

function createNewOrchestrations(
  orchestrationsState: IOrchestrationRow[],
  foundOrchestrationIndex: number,
  updatedItem: IOrchestrationRow
) {
  return orchestrationsState?.map((orchestration, index) => {
    if (index === foundOrchestrationIndex) {
      return updatedItem;
    }

    return {
      ...orchestration,
      duration:
        orchestration.status === ORCHESTRATION_STATUSES.IN_PROGRESS
          ? getOrchestrationDuration(orchestration)
          : orchestration.duration,
    };
  });
}
function updateOrchestrationItem(
  type: 'updateStatus' | 'updateStep',
  foundOrchestration: IOrchestrationRow,
  updatedOrchestration: IUpdateOrchestrationStatus | IStepInformation
) {
  return type === 'updateStatus'
    ? updateJobStatusInTable(
        foundOrchestration,
        updatedOrchestration as IUpdateOrchestrationStatus
      )
    : (updateJobStepsInTable(
        foundOrchestration,
        updatedOrchestration as IStepInformation
      ) as IOrchestrationRow);
}
