import {
  fetchAvailableModules,
  fetchAvailableModulesWithoutLoading,
} from 'api/manageModules/manageModulesThunks';
import style from './ManageModules.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { ManageModulesBanner } from 'components/ManageModules/ManageModulesBanner/ManageModulesBanner';
import { ManageModulesTabs } from 'components/ManageModules/ManageModulesTabs/ManageModulesTabs';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from 'app/hooks';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';
import { clearState } from 'app/manageModules/ManageModulesSlice';

const ManageModules = () => {
  const dispatch = useAppDispatch();
  const isUnmounted = useRef(false);
  const intervalId = useRef<NodeJS.Timer | null>(null);

  const startInterval = useCallback(() => {
    if (intervalId.current !== null) {
      return;
    }

    intervalId.current = setInterval(() => {
      if (isUnmounted.current) {
        return;
      }
      try {
        dispatch(fetchAvailableModulesWithoutLoading());
      } catch (error) {
        consoleErrorMessage(error);
      }
    }, 5000);
  }, [dispatch]);

  const getInfo = useCallback(async () => {
    try {
      await dispatch(fetchAvailableModules())?.unwrap();
      startInterval();
    } catch (error) {
      consoleErrorMessage(error);
    }
  }, [dispatch, startInterval]);

  useEffect(() => {
    isUnmounted.current = false;
    getInfo();
    return () => {
      isUnmounted.current = true;
      dispatch(clearState());
      if (intervalId.current !== null) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    };
  }, [getInfo, dispatch]);

  return (
    <div data-testid="ManageModules" className={style['manage-modules']}>
      <CsbErrorBoundary>
        <ManageModulesBanner />
        <ManageModulesTabs />
      </CsbErrorBoundary>
    </div>
  );
};

export default ManageModules;
