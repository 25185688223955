import { ExtendedColDef } from 'interfaces/table.interface';
import { TABLE_FIELDS } from 'utils/common-constants';

export const MANAGE_MODULES_TABLE_COLUMNS: ExtendedColDef[] = [
  {
    headerName: 'Module Name',
    field: TABLE_FIELDS.name,
    cellRenderer: 'cellModuleName',
    cellClass: 'csb-visible-cell',
    menuTabs: [],
    minWidth: 300,
    suppressSizeToFit: false,
  },
  {
    headerName: 'Version',
    field: TABLE_FIELDS.version,
    cellRenderer: 'cellWithTooltip',
    cellClass: 'csb-visible-tooltip',
    minWidth: 90,
    maxWidth: 90,
    menuTabs: [],
    suppressSizeToFit: true,
  },
  {
    headerName: 'Workers',
    field: TABLE_FIELDS.workers,
    minWidth: 86,
    maxWidth: 86,
    menuTabs: [],
    suppressSizeToFit: false,
  },
  {
    headerName: 'Consumers',
    field: TABLE_FIELDS.consumers,
    minWidth: 104,
    maxWidth: 104,
    menuTabs: [],
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: false,
  },
  {
    headerName: 'Total Capacity',
    field: TABLE_FIELDS.totalCapacity,
    minWidth: 123,
    maxWidth: 123,
    menuTabs: [],
    suppressSizeToFit: false,
  },
  {
    headerName: 'Status',
    field: TABLE_FIELDS.status,
    suppressSizeToFit: false,
    minWidth: 74,
    maxWidth: 74,
    menuTabs: [],
    cellRenderer: 'cellColorStatus',
    cellClass: 'csb-visible-tooltip',
  },
];

export const MANAGE_PROPERTIES_TABLE_COLUMNS: ExtendedColDef[] = [
  {
    headerName: 'Parameter Name',
    field: TABLE_FIELDS.name,
    menuTabs: [],
    minWidth: 90,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Type',
    field: TABLE_FIELDS.type,
    minWidth: 90,
    menuTabs: [],
    suppressSizeToFit: true,
  },
  {
    headerName: 'Required',
    field: TABLE_FIELDS.required,
    minWidth: 95,
    maxWidth: 95,
    menuTabs: [],
    suppressSizeToFit: false,
  },
  {
    headerName: 'Default',
    field: TABLE_FIELDS.default,
    suppressSizeToFit: false,
    minWidth: 86,
    menuTabs: [],
    cellRenderer: 'cellWithTooltip',
    cellClass: 'csb-visible-tooltip',
  },
];

const cellClassRules = {
  'header-cell': 'data.section === "title-header"',
  'header-properties-cell':
    'data.section === "properties-header" && data.value === "Value"',
  'properties-cell': 'data.section === "values"',
};

export const MANAGE_INSTANCES_TABLE_COLUMNS: ExtendedColDef[] = [
  {
    headerName: '',
    suppressMenu: true,
    field: 'name',
    cellRenderer: 'cellModuleServerName',
    colSpan: (params) => {
      if (params.data.section === 'title-header') {
        return 2;
      } else if (params.data.section === 'properties-header') {
        return 1;
      } else {
        return 1;
      }
    },
    cellClassRules: cellClassRules,
    sortable: false,
    suppressSizeToFit: true,
  },
  {
    headerName: '',
    suppressMenu: true,
    field: 'value',
    cellClass: 'value-column csb-visible-tooltip',
    cellClassRules: cellClassRules,
    cellRenderer: 'cellWithTooltip',
    sortable: false,
    minWidth: 70,
    suppressSizeToFit: false,
  },
];

export const MANAGE_MODULES_USED_IN_TABLE_COLUMNS: ExtendedColDef[] = [
  {
    headerName: 'Orchestration Name',
    suppressMenu: true,
    field: 'name',
    sortable: false,
    cellRenderer: 'cellLink',
  },
];

export const MODULE_NAME_PARAM = 'name';
export const MODULE_DISPLAY_PARAM = 'display';
export const MODULE_DATA = 'module';
