import { Tooltip, TooltipBody } from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useEffect, useState } from 'react';
import style from './CellColorStatus.module.scss';
import { IModuleRow } from 'interfaces/modules/module.interface';

const CellColorStatus = (props: any) => {
  const rowData: IModuleRow = props.data;
  const [isActive, setIsActive] = useState<boolean>(
    rowData.status === 'active' ? true : false
  );
  useEffect(() => {
    setIsActive(rowData.status === 'active' ? true : false);
  }, [rowData]);

  const [isStatusTooltipOpen, setIsStatusTooltipOpen] =
    useState<boolean>(false);

  return (
    <CsbErrorBoundary>
      {rowData && rowData.status && (
        <>
          <div
            className={style['cell-container']}
            data-testid="CellColorStatus"
            onMouseOver={() => setIsStatusTooltipOpen(true)}
            onMouseLeave={() => setIsStatusTooltipOpen(false)}
          >
            <div
              id={`status-${rowData.id}`}
              className={
                style[`${isActive ? 'circle-active' : 'circle-inactive'}`]
              }
            ></div>
          </div>
          <Tooltip
            position="left"
            targetSelector={`#status-${rowData.id}`}
            isOpen={isStatusTooltipOpen}
            id={`status-${rowData.id}-tooltip`}
            controlMode="controlled"
          >
            <TooltipBody slot="tooltipBody">
              <p
                data-testid="CellColorStatusTooltip"
                className={style['tooltip-name']}
              >
                {rowData.status}
              </p>
            </TooltipBody>
          </Tooltip>
        </>
      )}
    </CsbErrorBoundary>
  );
};

export default CellColorStatus;
