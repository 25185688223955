import RunOrchestrationTable from 'components/TableContainer/RunOrchestrationTable/RunOrchestrationTable';
import {
  IWorkflow,
  IWorkflowProduct,
} from 'interfaces/runOrchestration/workflow-row';
import { ExtendedColDef, PAGE_OPTIONS } from 'interfaces/table.interface';
import { useWorkflowsViews } from 'utils/hooks/useWorkflowsViews';
import style from './ListView.module.scss';
import { useAppSelector } from 'app/hooks';
import { selectWorkflows } from 'app/runOrchestrations/RunOrchestrationsSlice';
import { IServerWorkflow } from 'interfaces/workflow.interface';

interface IListView {
  serverWorkflowsData: IServerWorkflow[];
  getRowId: (params: any) => any;
  tableColumns: ExtendedColDef[];
}

export const ListView = ({
  serverWorkflowsData,
  tableColumns,
  getRowId,
}: IListView) => {
  useWorkflowsViews({ initialWorkflows: serverWorkflowsData });
  const workflowsState: IWorkflow[] | IWorkflowProduct[] =
    useAppSelector(selectWorkflows);
  return (
    <div data-testid="ListView" className={style['container']}>
      <RunOrchestrationTable
        items={workflowsState ?? []}
        columns={tableColumns}
        onGridReadyAdditionalMethods={[]}
        additionalData={{ getRowId }}
        page={PAGE_OPTIONS.runOrchestrations}
        additionalStyles={''}
      />
    </div>
  );
};
