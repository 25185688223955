import { ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/shared/agGridColumn';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectOrchestrations,
  modifySseOrchestrationEvents,
  setPaginatorInfo,
  selectSelectedStatus,
} from '../../../app/dashboard/DashboardSlice';
import CellStatus from '../../CellStatus/CellStatus';
import CellLink from 'components/CellLink/CellLink';
import {
  ExtendedColDef,
  IOnGridReadyAdditionalMethod,
  PAGE_OPTIONS,
} from 'interfaces/table.interface';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './DashboardTable.module.scss';
import DateTimeInput from 'components/DateTimeInput/DateTimeInput';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import { selectWorkflows } from 'app/runOrchestrations/RunOrchestrationsSlice';
import { SSE_STATUS } from 'utils/common-constants';
import CellOrchestrationName from 'components/CellOrchestrationName/CellOrchestrationName';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import { useInfiniteScroll } from 'utils/hooks/useInfinityScroll';
import {
  IWorkflow,
  IWorkflowProduct,
} from 'interfaces/runOrchestration/workflow-row';
import { selectConnectionStatus } from 'app/auth/AuthSlice';
import {
  useOrchestrationStateUpdate,
  useDashboardTableEvents,
  useWorkflowUpdate,
  useHandleOrchestrationEvents,
} from './hooks';
import { fetchAllOrchestrations } from 'api/orchestrations/orchestrationsThunk';

const defaultColumnDef: ColDef = {
  width: 200,
  sortable: true,
  suppressColumnsToolPanel: true,
  icons: {
    sortAscending:
      '<span class="wk-icon-arrow-down" aria-hidden="true"/></span>',
    sortDescending:
      '<span class="wk-icon-arrow-up" aria-hidden="true"/></span>',
  },
};

const gridOptionComponents = {
  cellStatus: CellStatus,
  cellLink: CellLink,
  cellOrchestrationName: CellOrchestrationName,
  agDateInput: DateTimeInput,
};

const adjustColumnsWidth = (
  gridColumnApiRef: MutableRefObject<ColumnApi | null>,
  gridApiRef: MutableRefObject<GridApi<any> | null>
) => {
  setTimeout(() => {
    gridColumnApiRef.current?.autoSizeAllColumns();
    gridApiRef.current?.sizeColumnsToFit();
  }, 200);
};

interface IDashboardTable {
  columns: ExtendedColDef[] | ColDef[];
  isServerSideFiltering?: boolean;
  additionalData?: any;
  onGridReadyAdditionalMethods?: IOnGridReadyAdditionalMethod[];
  additionalStyles?: string;
  page?: PAGE_OPTIONS;
}

const DashboardTable = (props: IDashboardTable): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    activeFilters: activeFiltersState,
    loading,
    sseOrchestrationsEvents,
  } = useAppSelector((state) => state.dashboardContainer);
  const selectedStatus = useAppSelector(selectSelectedStatus);
  const workflows: IWorkflow[] | IWorkflowProduct[] =
    useAppSelector(selectWorkflows);
  const orchestrations: IOrchestrationRow[] =
    useAppSelector(selectOrchestrations);
  const paginatorPageRef = useRef<number>(1);
  const isLoadingNewPage: any = useRef<boolean>(false);
  const isMultiPage = useRef<boolean>(true);
  const isAbleToLoadNewPage = useRef<boolean>(true);
  const connectionStatusState = useAppSelector(selectConnectionStatus);
  const requestRef = useRef<any>(null);

  const {
    onGridReady,
    onRowDataUpdated,
    onRowSelected,
    onGridSizeChanged,
    onFilterOpened,
    onFilterChanged,
    onSortChanged,
    gridApiRef,
    gridColumnApiRef,
    filtersState,
    filterModelState,
    activeFilters,
    filterModel,
    filterInfo,
  } = useDashboardTableEvents(props);

  const INFINITY_SCROLL_TRIGGER = 300;

  const { orchestrationsState, setOrchestrationState } =
    useOrchestrationStateUpdate(
      orchestrations,
      isMultiPage,
      isLoadingNewPage,
      selectedStatus
    );

  useWorkflowUpdate(gridApiRef, filterModel, workflows);

  useHandleOrchestrationEvents({
    sseOrchestrationsEvents,
    setOrchestrationState,
    orchestrationsState,
    orchestrations,
    gridApiRef,
    dispatch,
    modifySseOrchestrationEvents,
    selectedStatus,
  });

  const getLoading = useCallback(() => {
    if (gridApiRef.current == null) {
      setTimeout(() => {
        getLoading();
      }, 100);
    } else {
      loading
        ? gridApiRef.current?.showLoadingOverlay()
        : gridApiRef.current?.hideOverlay();
    }
    isLoadingNewPage.current = loading;
  }, [loading, gridApiRef]);

  const loadDashboardInfoHandler = async () => {
    paginatorPageRef.current = paginatorPageRef.current + 1;
    dispatch(
      setPaginatorInfo({ page: paginatorPageRef.current, pageSize: 20 })
    );
    requestRef.current = dispatch(
      fetchAllOrchestrations({
        ...filterInfo.current,
        activePage: paginatorPageRef.current,
      })
    );
    const result = await requestRef.current?.unwrap();
    if (result?.jobs?.length === 0) {
      isAbleToLoadNewPage.current = false;
    }
  };

  const containerRef = useInfiniteScroll(
    loadDashboardInfoHandler,
    isAbleToLoadNewPage,
    connectionStatusState !== SSE_STATUS.RESTORED
      ? isLoadingNewPage
      : { current: false },
    INFINITY_SCROLL_TRIGGER
  );

  useEffect(() => {
    if (connectionStatusState === SSE_STATUS.RESTORED) {
      isMultiPage.current = false;
      paginatorPageRef.current = 1;
      if (requestRef.current) {
        requestRef.current.abort();
      }
    }
  }, [connectionStatusState]);

  useEffect(() => {
    filterInfo.current = filtersState;
    paginatorPageRef.current = 1;
    isAbleToLoadNewPage.current = true;
    isMultiPage.current = false;
  }, [filtersState]);

  useEffect(() => {
    activeFilters.current = activeFiltersState;
  }, [activeFiltersState]);

  useEffect(() => {
    filterModel.current = filterModelState;
  }, [filterModelState]);

  useEffect(() => {
    getLoading();
    adjustColumnsWidth(gridColumnApiRef, gridApiRef);
  }, [getLoading, orchestrations]);

  const gridOptions: GridOptions = {
    components: gridOptionComponents,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true,
    suppressRowVirtualisation: false,
    localeText: {
      search: 'Start typing...',
    },
    rowMultiSelectWithClick: true,
    defaultColDef: defaultColumnDef,
    headerHeight: 40,
    rowHeight: 40,
    loadingOverlayComponent: TableOverlayComponent,
    loadingOverlayComponentParams: { page: props.page },
    overlayNoRowsTemplate: '<span>No results</span>',
    animateRows: true,
    domLayout: 'autoHeight',
    onGridReady,
    onRowDataUpdated,
    onRowSelected,
    onGridSizeChanged,
    onSortChanged,
    onFilterChanged,
    onFilterOpened,
  };

  return (
    <CsbErrorBoundary>
      <div className={style['csb-table']}>
        <div
          className={`${style['csb-table__container']} wk-advanced-table-container`}
          data-testid="TableContainer"
        >
          <div
            className={`wk-advanced-table ${style['csb-table__content']} ${
              props.additionalStyles ?? style['wk-striped']
            }`}
            ref={containerRef}
          >
            <div className={style['csb-table__top-border']}></div>
            <AgGridReact
              gridOptions={gridOptions}
              rowData={orchestrationsState}
              {...props.additionalData}
            >
              {props.columns.map((column) => (
                <AgGridColumn {...column} key={column.field} />
              ))}
            </AgGridReact>
          </div>
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

export default DashboardTable;
