import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import style from './CellModuleName.module.scss';
import { RadioField } from '@wk/components-react16/dist/radio-field/radio-field';
import { Tooltip } from '@wk/components-react16/dist/tooltip/tooltip';
import { TooltipBody } from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { IModuleRow, IModuleServer } from 'interfaces/modules/module.interface';
import {
  selectSelectedModule,
  setSelectedModuleFromRow,
} from 'app/manageModules/ManageModulesSlice';
import useWkTooltipOutsideComponent from 'utils/hooks/wk-tooltips/useWkTooltipOutsideComponent';

interface ICellModuleName {
  data: Partial<IModuleRow>;
}

const CellModuleName = (props: ICellModuleName) => {
  const rowData: Partial<IModuleRow> = props.data;
  const dispatch = useAppDispatch();
  const name = rowData.name;
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const { moveWkTooltipToOutsideElement } = useWkTooltipOutsideComponent(
    `module-name-${rowData.id}-tooltip`
  );

  const cellRef = useRef<MutableRefObject<any> | any>();

  useEffect(() => {
    if (isTooltipOpen && (name as string)?.length > 30) {
      moveWkTooltipToOutsideElement();
    }
  }, [isTooltipOpen]);

  const selectedModule: IModuleServer | IModuleRow =
    useAppSelector(selectSelectedModule);

  useEffect(() => {
    if (isSelected) {
      cellRef.current?.parentElement?.parentElement?.classList.add(
        'csb-focus-row'
      );
    } else {
      cellRef.current?.parentElement?.parentElement?.classList.remove(
        'csb-focus-row'
      );
    }
  }, [selectedModule]);

  const selectOrchestration = () => {
    if (selectedModule?.id !== rowData?.id) {
      dispatch(
        setSelectedModuleFromRow({
          id: rowData.id as number,
          slave: rowData.slave as string,
        })
      );
    }
  };

  const isSelected = useMemo(() => {
    return (
      selectedModule?.id === rowData.id &&
      selectedModule?.slave === rowData.slave
    );
  }, [selectedModule]);

  return (
    <CsbErrorBoundary>
      <div
        className={style['cell-container']}
        data-testid="CellModuleName"
        id={`module-name-${rowData.id}-cell`}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        ref={cellRef}
      >
        <div className={style['cell-item']}>
          <RadioField>
            <input
              type="radio"
              name="cellNameRadioBtn"
              data-testid="CellInput"
              onClick={() => selectOrchestration()}
              onChange={() => {}}
              checked={isSelected}
            />
          </RadioField>
        </div>
        <span className={style['cell-name']}>{name}</span>
        {name && name?.length > 54 && (
          <Tooltip
            position="right"
            targetSelector={`#module-name-${rowData.id}-cell`}
            id={`module-name-${rowData.id}-tooltip`}
            controlMode="uncontrolled"
            isOpen={isTooltipOpen}
          >
            <TooltipBody slot="tooltipBody">
              <span data-testid="CellTooltip">
                <span
                  id={`name-${rowData.id}`}
                  style={{ whiteSpace: 'break-spaces' }}
                >
                  {name}
                </span>
              </span>
            </TooltipBody>
          </Tooltip>
        )}
      </div>
    </CsbErrorBoundary>
  );
};

export default CellModuleName;
