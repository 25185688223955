import { CardContainer } from '@wk/components-react16/dist/card-container/card-container';
import style from './ManageModulesDetailedInformation.module.scss';
import { CardContainerHeader } from '@wk/components-react16/dist/card-container-header/card-container-header';
import { CardContainerBody } from '@wk/components-react16/dist/card-container-body/card-container-body';
import {
  IModuleInstancesRow,
  IModuleParametersRow,
  IModuleServer,
  IModuleUsedByRow,
} from 'interfaces/modules/module.interface';
import { CardContainerContent } from '@wk/components-react16/dist/card-container-content/card-container-content';
import { CardTileSubtitle, CardTileTitle } from '@wk/components-react16';

import { useAppDispatch } from 'app/hooks';
import { setSelectedModule } from 'app/manageModules/ManageModulesSlice';
import { SrOnly } from '@wk/components-react16/dist/sr-only/sr-only';
import { ButtonFieldIcon } from '@wk/components-react16/dist/button-field-icon/button-field-icon';
import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import ModuleDetailsTable from 'components/TableContainer/ModuleDetailsTable/ModuleDetailsTable';
import {
  MANAGE_INSTANCES_TABLE_COLUMNS,
  MANAGE_MODULES_USED_IN_TABLE_COLUMNS,
  MANAGE_PROPERTIES_TABLE_COLUMNS,
} from 'pages/ManageModules/ManageModules.constants';
import { memo, useEffect, useRef, useState } from 'react';
import { useGetWorkflowsByModuleQuery } from 'api/workflows/workflows';
import { PAGE_OPTIONS } from 'interfaces/table.interface';

interface IManageModulesDetailedInformation {
  selectedModule: IModuleServer;
}

const subtitleClass = 'sub-title-bold';
const tableContainerClass = 'table-container';
const ManageModulesDetailedInformation = memo(
  ({ selectedModule }: IManageModulesDetailedInformation) => {
    const dispatch = useAppDispatch();
    const topRef = useRef<HTMLDivElement>(null);
    const orchestrationsByModule = useGetWorkflowsByModuleQuery({
      slave: selectedModule.slave,
      moduleTextId: selectedModule.textId,
      moduleVersion: selectedModule.version,
    })?.data;
    const [inputParameters, setInputParameters] = useState<
      IModuleParametersRow[]
    >([]);
    const [instancesInformation, setInstancesInformation] = useState<
      IModuleInstancesRow[]
    >([]);
    const [usedByInformation, setUsedByInformation] = useState<
      IModuleUsedByRow[]
    >([]);

    useEffect(() => {
      if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: 'auto' });
      }
      setInputParameters(
        selectedModule?.parameters?.map((param) => {
          return {
            id: param.id,
            name: param.name,
            type: param.type,
            required: param.required === true ? 'Yes' : 'No',
            default: param.default,
          };
        })
      );
      setInstancesInformation(
        selectedModule?.instances
          ?.map((instance) => {
            const array = [];
            array.push({
              id: Math.floor(Math.random() * 1000),
              name: `EC2 Server ${instance.hostname}`,
              value: undefined,
              tenant: selectedModule.slave,
              section: 'title-header' as const,
            });
            array.push({
              id: Math.floor(Math.random() * 1000),
              name: 'Property name',
              value: 'Value',
              section: 'properties-header' as const,
            });
            for (const [key, val] of Object.entries(instance.properties)) {
              array.push({
                id: Math.floor(Math.random() * 1000),
                name: key,
                value: val,
                section: 'values' as const,
              });
            }
            return array;
          })
          .flat()
      );
    }, [selectedModule]);

    useEffect(() => {
      if (
        orchestrationsByModule?.data &&
        Object.keys(orchestrationsByModule.data).length > 0
      ) {
        const formatedOrchestrationsByModule: IModuleUsedByRow[] = [];
        const sortedData = Object.entries(orchestrationsByModule.data).sort(
          (a, b) => a[1].localeCompare(b[1])
        );
        for (const [key, val] of sortedData) {
          formatedOrchestrationsByModule.push({
            moduleId: selectedModule.id,
            slave: selectedModule.slave ?? '',
            name: val,
            id: key,
          });
        }
        setUsedByInformation(formatedOrchestrationsByModule);
      } else {
        const formatedOrchestrationsByModule: IModuleUsedByRow[] = [];
        const data = {
          [Math.floor(Math.random() * 1000)]: 'Apollo Import Raw',
          [Math.floor(Math.random() * 1000)]: 'Apollo User Import',
          [Math.floor(Math.random() * 1000)]: 'Version Compare',
        };
        const sortedData = Object.entries(data).sort((a, b) =>
          a[1].localeCompare(b[1])
        );
        for (const [key, val] of sortedData) {
          formatedOrchestrationsByModule.push({
            moduleId: selectedModule.id,
            slave: selectedModule.slave ?? '',
            name: val,
            id: key,
          });
        }
        setUsedByInformation(formatedOrchestrationsByModule);
      }
    }, [orchestrationsByModule]);

    const removeModule = () => {
      dispatch(setSelectedModule(null));
    };

    return (
      <div className={style['container']}>
        <section
          className={style['card-container']}
          data-testid="ManageModulesDetailedInformation"
        >
          <CardContainer>
            <CardContainerHeader>
              <h3 className={style['title']}>Detailed Module Information </h3>
              <div className={style['header-buttons']}>
                <ButtonFieldIcon>
                  <button
                    type="button"
                    flow-id="close-button"
                    onClick={() => removeModule()}
                  >
                    <Iconsvg name="close"></Iconsvg>
                    <SrOnly>Close modal</SrOnly>
                  </button>
                </ButtonFieldIcon>
              </div>
            </CardContainerHeader>
            <CardContainerBody>
              <CardContainerContent>
                <div className={style['body-container']} ref={topRef}>
                  <CardTileTitle>
                    <h4 className={style['title']}>{selectedModule.name} </h4>
                  </CardTileTitle>
                  <CardTileSubtitle>
                    <span className={style['sub-title']}>
                      {selectedModule.version}
                    </span>
                  </CardTileSubtitle>
                  <p className={style['description']}>
                    {selectedModule.description}
                  </p>
                  <span className={style[subtitleClass]}>Input Parameters</span>
                  <div className={style[tableContainerClass]}>
                    <ModuleDetailsTable
                      columns={MANAGE_PROPERTIES_TABLE_COLUMNS}
                      items={inputParameters}
                      page={PAGE_OPTIONS.manageModules}
                    />
                  </div>
                  <span className={style[subtitleClass]}>Deployed to</span>
                  <div className={style[tableContainerClass]}>
                    <ModuleDetailsTable
                      columns={MANAGE_INSTANCES_TABLE_COLUMNS}
                      items={instancesInformation}
                      page={PAGE_OPTIONS.manageModules}
                      instanceDetails={true}
                    />
                  </div>
                  <span className={style[subtitleClass]}>Used in</span>
                  <div
                    className={style[tableContainerClass]}
                    data-testid="ManageModulesUsedInTable"
                  >
                    <ModuleDetailsTable
                      columns={MANAGE_MODULES_USED_IN_TABLE_COLUMNS}
                      items={usedByInformation}
                      page={PAGE_OPTIONS.manageModules}
                    />
                  </div>
                </div>
              </CardContainerContent>
            </CardContainerBody>
          </CardContainer>
        </section>
      </div>
    );
  }
);
export default ManageModulesDetailedInformation;
