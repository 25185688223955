import { IModuleRow, IModuleServer } from 'interfaces/modules/module.interface';

export const moduleToTable = (modules: IModuleServer[]): IModuleRow[] => {
  return modules?.map((module) => {
    const consumersCount = module.instances.reduce(
      (acc, item) => item.consumersCount + acc,
      0
    );
    return {
      id: module.id !== null ? module.id : Math.floor(Math.random() * 101020),
      name: module.name,
      version: module.version,
      workers: module.instances.length,
      consumers: consumersCount,
      totalCapacity: module.instances.length * consumersCount,
      status: consumersCount > 0 ? 'active' : 'inactive',
      slave: module.slave,
    };
  });
};
