import { createAsyncThunk } from '@reduxjs/toolkit';
import { IServerWorkflow } from 'interfaces/workflow.interface';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';

export const fetchWorkflows = createAsyncThunk(
  'runOrchestrations/fetchWorkflows',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/csb/workflows', {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: IServerWorkflow[] = await response.json();
      return data;
    } catch (error) {
      consoleErrorMessage(error);
      return rejectWithValue(error);
    }
  }
);
