import { useNodes, StepEdge } from 'reactflow';
import {
  getSmartEdge,
  pathfindingJumpPointNoDiagonal,
} from '@tisoap/react-flow-smart-edge';
import { useMemo } from 'react';

export function SmartEdges(props: any) {
  const {
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    markerStart,
    markerEnd,
  } = props;

  const nodes = useNodes();

  const smartOptions = {
    nodePadding: 16,
    gridRatio: 5,
    drawEdge: (source: any, target: any, path: any) => {
      let svgPathString = `M ${path?.[0]?.[0]}, ${source.y} `;

      svgPathString += `L ${path?.[0]?.[0]}, ${path?.[0]?.[1] - 10} `;
      path.forEach((point: any) => {
        const [x, y] = point;
        svgPathString += `L ${x}, ${y} `;
      });
      svgPathString += `L ${path?.[path.length - 1]?.[0]}, ${
        path?.[path.length - 1]?.[1] + 10
      } `;

      svgPathString += `L ${path[path.length - 1][0]}, ${target.y} `;
      return svgPathString;
    },
    generatePath: pathfindingJumpPointNoDiagonal,
  };

  const getSmartEdgeResponse: any = useMemo(
    () =>
      getSmartEdge({
        sourcePosition,
        targetPosition,
        sourceX,
        sourceY,
        targetX,
        targetY,
        nodes,
        options: smartOptions,
      }),
    []
  );

  if (getSmartEdgeResponse === null) {
    return <StepEdge {...props} />;
  }

  const { svgPathString } = getSmartEdgeResponse;

  return (
    <>
      <path
        style={style}
        className="react-flow__edge-path"
        d={svgPathString}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
    </>
  );
}
