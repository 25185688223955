import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
} from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectOrchetsrationInstanceStep,
  switchOpenStatus,
  selectPropertiesPanel,
} from 'app/propertiesPanel/PropertiesPanelSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import TextFieldOrTextAreaSwitcher from 'components/TextFieldOrTextAreaSwitcher/TextFieldOrTextAreaSwitcher';
import {
  IOrchestrationInstancePanel,
  IOrchestrationSidePanelSteps,
} from 'interfaces/orchestrationDiagram/orchestration-diagram';
import style from './OrchestrationInstanceAccordion.module.scss';
import { PROPERTIES_PANEL_OPTIONS } from 'pages/RunOrchestration/RunOrchestrations.constants';
import { useEffect, useState } from 'react';
import { ORCHESTRATION_STATUSES } from 'utils/common-constants';
import { getOrchestrationDuration } from 'utils/commonFunctions/CommonFunctions';

interface IOrchestrationInstanceAccordion {
  variation?: PROPERTIES_PANEL_OPTIONS | null;
}
export const OrchestrationInstanceAccordion = ({
  variation,
}: IOrchestrationInstanceAccordion) => {
  const instancePanelStep: IOrchestrationInstancePanel | undefined =
    useAppSelector(selectOrchetsrationInstanceStep);
  const orchestrationSteps: IOrchestrationSidePanelSteps[] = useAppSelector(
    selectPropertiesPanel
  );
  const dispatch = useAppDispatch();
  const [inProgressDuration, setInProgressDuration] = useState('');
  let durationIntervalHandler: NodeJS.Timer;

  useEffect(() => {
    if (instancePanelStep?.status === ORCHESTRATION_STATUSES.IN_PROGRESS) {
      (function increaseTimeloop() {
        durationIntervalHandler = setInterval(() => {
          if (
            instancePanelStep &&
            instancePanelStep?.status === ORCHESTRATION_STATUSES.IN_PROGRESS
          ) {
            const updatedDuration = getOrchestrationDuration(
              instancePanelStep as any
            );
            setInProgressDuration(updatedDuration);
          } else {
            clearInterval(durationIntervalHandler);
          }
        }, 1000);
      })();
    }
    return () => clearInterval(durationIntervalHandler);
  }, [instancePanelStep]);

  const formmatFailedStep = (): string => {
    const failedStepIndex = orchestrationSteps.findIndex(
      (step) => step.id === instancePanelStep?.firstFailedStepId
    );
    if (failedStepIndex !== -1) {
      return `Step ${failedStepIndex + 1} ${
        orchestrationSteps[failedStepIndex].name
      }`;
    } else {
      return '-';
    }
  };

  return (
    <CsbErrorBoundary>
      <div
        data-testid="OrchestrationInstanceAccordion"
        className={`${style['orchestration-instance-accordion']} ${
          instancePanelStep?.isOpen ? style['accordion-wk-open'] : ''
        }`}
      >
        <AccordionItem
          isOpen={instancePanelStep?.isOpen ?? true}
          onUserRequest={(event: boolean) =>
            dispatch(switchOpenStatus({ id: undefined, swtichInstance: true }))
          }
          isScrollbarEnabled={false}
        >
          <AccordionItemHeader slot="accordionItemHeader">
            <p className={style['accordion-header-title']}>
              {instancePanelStep?.name}
            </p>
          </AccordionItemHeader>
          <AccordionItemBody slot="accordionItemBody">
            <section className={style['accordion-body']}>
              {variation !== PROPERTIES_PANEL_OPTIONS.RUN_ORCHESTRATION && (
                <TextFieldOrTextAreaSwitcher
                  label="Orchestration ID"
                  id="orchestrationId"
                  value={instancePanelStep?.id?.toString()}
                />
              )}
              <TextFieldOrTextAreaSwitcher
                label="Orchestration name"
                id="orchestrationName"
                value={instancePanelStep?.name}
              />
              {variation !== PROPERTIES_PANEL_OPTIONS.RUN_ORCHESTRATION && (
                <>
                  <TextFieldOrTextAreaSwitcher
                    label="Failed module"
                    id="failedModule"
                    testid="OrchestrationInstanceAccordion-failedStep"
                    value={formmatFailedStep()}
                  />
                  <TextFieldOrTextAreaSwitcher
                    label="Duration"
                    id="jobDuration"
                    value={
                      instancePanelStep?.status ===
                      ORCHESTRATION_STATUSES.IN_PROGRESS
                        ? inProgressDuration
                        : instancePanelStep?.duration ?? '-'
                    }
                  />
                  <TextFieldOrTextAreaSwitcher
                    label="Start time"
                    id="jobStartTime"
                    value={instancePanelStep?.startTime ?? '-'}
                  />
                  <TextFieldOrTextAreaSwitcher
                    label="End time"
                    id="jobEndTime"
                    value={instancePanelStep?.endTime ?? '-'}
                  />
                  <span className={style['accordion-subtitle']}>
                    Additional information
                  </span>
                  <TextFieldOrTextAreaSwitcher
                    label="Submit time"
                    id="jobSubmitTime"
                    value={instancePanelStep?.submitTime ?? '-'}
                  />
                  <TextFieldOrTextAreaSwitcher
                    label="Owner"
                    id="jobOwner"
                    value={instancePanelStep?.owner ?? '-'}
                  />
                </>
              )}

              {instancePanelStep?.inputName && (
                <TextFieldOrTextAreaSwitcher
                  label="Input name"
                  id="jobInputName"
                  value={
                    instancePanelStep?.inputName.replace(/,/g, ',\n\n') ?? '-'
                  }
                />
              )}
              {instancePanelStep?.inputSize && (
                <TextFieldOrTextAreaSwitcher
                  label="Input size"
                  id="jobInputSize"
                  value={instancePanelStep?.inputSize ?? '-'}
                />
              )}
              {instancePanelStep?.timeoutAfternH && (
                <TextFieldOrTextAreaSwitcher
                  label="Timeout after nH"
                  id="timeoutAfternH"
                  value={instancePanelStep?.timeoutAfternH?.toString() ?? '-'}
                />
              )}
              {instancePanelStep?.supportsPrioritization && (
                <TextFieldOrTextAreaSwitcher
                  label="Orchestration priority"
                  id="orchestrationPriority"
                  value={
                    instancePanelStep?.orchestrationPriority?.toString() ?? '-'
                  }
                />
              )}
            </section>
          </AccordionItemBody>
        </AccordionItem>
      </div>
    </CsbErrorBoundary>
  );
};
