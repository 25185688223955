import {
  ColDef,
  ColumnApi,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/shared/agGridColumn';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ITableContainerProps } from 'interfaces/table.interface';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import styleDetails from './ModuleDetailsTable.module.scss';
import styleModuleInstance from './ModuleInstanceDetailsTable.module.scss';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import {
  IModuleInstancesRow,
  IModuleParametersRow,
  IModuleUsedByRow,
} from 'interfaces/modules/module.interface';
import CellWithTooltip from 'components/CellWithTooltip/CellWithTooltip';
import CellLink from 'components/CellLink/CellLink';
import CellModuleServerName from 'components/CellModuleServerName/CellModuleServerName';

const defaultColumnDef: ColDef = {
  width: 200,
  sortable: true,
  suppressColumnsToolPanel: true,
  icons: {
    sortAscending: '<span class="wk-icon-arrow-up" aria-hidden="true"/></span>',
    sortDescending:
      '<span class="wk-icon-arrow-down" aria-hidden="true"/></span>',
  },
};

interface IModuleDetailsTable extends ITableContainerProps {
  items: IModuleParametersRow[] | IModuleInstancesRow[] | IModuleUsedByRow[];
  instanceDetails?: boolean;
}

const gridOptionComponents = {
  cellWithTooltip: CellWithTooltip,
  cellLink: CellLink,
  cellModuleServerName: CellModuleServerName,
};

const ModuleDetailsTable = (props: IModuleDetailsTable): JSX.Element => {
  const gridApiRef = useRef<GridApi | null>(null);
  const gridColumnApiRef = useRef<ColumnApi | null>(null);
  const [loading, setLoading] = useState(true);
  const style = props.instanceDetails ? styleModuleInstance : styleDetails;

  const updateLoadingOverlay = () => {
    if (gridApiRef.current) {
      if (loading) {
        gridApiRef.current.showLoadingOverlay();
      } else {
        gridApiRef.current.hideOverlay();
      }
    }
  };

  useEffect(() => {
    updateLoadingOverlay();
  }, [loading]);

  useEffect(() => {
    const timer = setTimeout(() => {
      adjustColumnsWidth();
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [props.items]);

  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    if (gridApiRef.current) {
      adjustColumnsWidth();
      setLoading(false);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;
    setLoading(false);
  };

  const onGridSizeChanged = (event: GridSizeChangedEvent) => {
    gridApiRef.current?.sizeColumnsToFit();
  };

  const adjustColumnsWidth = () => {
    if (gridColumnApiRef) {
      gridColumnApiRef.current?.autoSizeAllColumns();
      gridApiRef.current?.sizeColumnsToFit();
    }
  };

  const getRowId = useCallback((params) => params.data.id, []);

  const gridOptions: GridOptions = {
    components: gridOptionComponents,
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true,
    rowBuffer: 10,
    defaultColDef: defaultColumnDef,
    headerHeight: props.instanceDetails ? 0 : 40,
    rowHeight: 40,
    domLayout: 'autoHeight',
    loadingOverlayComponent: TableOverlayComponent,
    loadingOverlayComponentParams: { page: props.page, style: 'default' },
    overlayNoRowsTemplate: '<span>No results</span>',
    animateRows: true,
    onGridReady,
    onGridSizeChanged,
    onFirstDataRendered,
  };

  return (
    <CsbErrorBoundary>
      <div className={style['csb-table']}>
        <div
          className={style['wk-advanced-table-container']}
          data-testid="TableContainer"
        >
          <div
            className={`wk-advanced-table ${style['csb-table__content']} ${
              props.additionalStyles ?? style['wk-striped']
            }`}
          >
            <AgGridReact
              gridOptions={gridOptions}
              rowData={props.items}
              getRowId={getRowId}
              {...props.additionalData}
            >
              {props.columns.map((column) => (
                <AgGridColumn {...column} key={column.field} />
              ))}
            </AgGridReact>
          </div>
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

export default ModuleDetailsTable;
