import { Iconsvg, Tooltip, TooltipBody } from '@wk/components-react16';
import { usePostDownloadLogMutation } from 'api/orchestrations/orchestrationApi';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { FC, useState } from 'react';
import { downloadLog } from 'utils/commonFunctions/CommonFunctions';
import style from './CellStatus.module.scss';
import {
  MAP_STATUS_ICON,
  ORCHESTRATION_STATUSES,
} from '../../utils/common-constants';
import { IOrchestrationRow } from '../../interfaces/dashboard/orchestration-row.interface';

const CellStatus: FC<any> = (props) => {
  const rowData: IOrchestrationRow = props.data;
  const [downloadHook, { isLoading: loading }] = usePostDownloadLogMutation();
  const [isStatusTooltipOpen, setIsStatusTooltipOpen] =
    useState<boolean>(false);
  const [isDownloadTooltipOpen, setIsDownloadTooltipOpen] =
    useState<boolean>(false);
  const validStatusesForDownload = [
    ORCHESTRATION_STATUSES.FINISHED,
    ORCHESTRATION_STATUSES.FINISHED_ERROR,
    ORCHESTRATION_STATUSES.FAILED,
  ];

  return (
    <CsbErrorBoundary>
      {rowData && rowData.status && (
        <div className={style['cell-status']} data-testid="CellStatus">
          <section
            onMouseOver={() => setIsStatusTooltipOpen(true)}
            onMouseLeave={() => setIsStatusTooltipOpen(false)}
            id={`status-${rowData.id}`}
          >
            <div className={style['cell-status__status-icon']}>
              <Iconsvg
                name={MAP_STATUS_ICON[rowData.status]?.name}
                title={MAP_STATUS_ICON[rowData.status]?.description}
                color={MAP_STATUS_ICON[rowData.status]?.color || 'black'}
                size={24}
              ></Iconsvg>
            </div>
          </section>
          <Tooltip
            position="left"
            targetSelector={`#status-${rowData.id}`}
            isOpen={isStatusTooltipOpen}
            id={`status-${rowData.id}-tooltip`}
            controlMode="controlled"
          >
            <TooltipBody slot="tooltipBody">
              <span data-testid="CellStatusTooltip">
                {MAP_STATUS_ICON[rowData.status]?.description}
              </span>
            </TooltipBody>
          </Tooltip>
          {validStatusesForDownload.includes(rowData.status) && (
            <>
              <section
                onMouseOver={() => setIsDownloadTooltipOpen(true)}
                onMouseLeave={() => setIsDownloadTooltipOpen(false)}
                id={`download-${rowData.id}`}
                data-testid="CellStatusDownloadSection"
              >
                {!loading && (
                  <span
                    className={style['cell-status__download-log']}
                    onClick={() => downloadLog(rowData, downloadHook)}
                  >
                    <Iconsvg
                      name="download-line"
                      color="#005B92"
                      title="Download Log"
                      size={24}
                    ></Iconsvg>
                  </span>
                )}
                {loading && (
                  <div className={style['cell-status__loader']}>
                    <Iconsvg
                      name="spinner-alt"
                      isSpin
                      isFill
                      size={22}
                    ></Iconsvg>
                  </div>
                )}
              </section>
              <Tooltip
                position="left"
                targetSelector={`#download-${rowData.id}`}
                id={`download-${rowData.id}-tooltip`}
                isOpen={isDownloadTooltipOpen}
                controlMode="controlled"
              >
                <TooltipBody slot="tooltipBody">
                  <span data-testid="CellStatusDownloadTooltip">
                    Download Log
                  </span>
                </TooltipBody>
              </Tooltip>
            </>
          )}
        </div>
      )}
    </CsbErrorBoundary>
  );
};

export default CellStatus;
