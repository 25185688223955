import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import OrchestrationFlowElement from 'components/OrchestrationFlowElement/OrchestrationFlowElement';
import { useEffect, useRef } from 'react';
import ReactFlow, {
  Controls,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow';
import style from './FlowChartDetails.module.scss';
import { SmartEdges } from 'components/SmartEdges/SmartEdges';

export enum REPOSITION_AMOUNT {
  REPOSITION_INCREMENT = 50,
  REPOSITION_DECREMENT = -50,
  INITIAL_OFFSET = 100,
}

interface FlowChartDetailsProps {
  nodes?: any;
  edges?: any;
  repositionOnNewData?: REPOSITION_AMOUNT;
}

const nodeTypes = {
  orchestrationFlowElement: OrchestrationFlowElement,
};
const edgeTypes = {
  smartEdges: SmartEdges,
};

const FlowChartContainer = ({
  nodes,
  edges,
  repositionOnNewData = REPOSITION_AMOUNT.INITIAL_OFFSET,
}: FlowChartDetailsProps) => {
  const { setViewport } = useReactFlow();
  const initialRender = useRef<boolean>(false);
  useEffect(() => {
    if (repositionOnNewData && nodes.length > 0) {
      const initialValue =
        initialRender.current === true
          ? REPOSITION_AMOUNT.INITIAL_OFFSET
          : repositionOnNewData;
      setViewport({ x: initialValue, y: 0, zoom: 1 }, { duration: 0 });
      initialRender.current = true;
    }
  }, [nodes]);

  return (
    <CsbErrorBoundary>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        preventScrolling={false}
        panOnScroll={true}
        className={style['react-flow__chart-details']}
      >
        <Controls showInteractive={false} />
      </ReactFlow>
    </CsbErrorBoundary>
  );
};

const FlowChartDetails = ({
  nodes,
  edges,
  repositionOnNewData,
}: FlowChartDetailsProps) => (
  <ReactFlowProvider>
    <FlowChartContainer
      nodes={nodes}
      edges={edges}
      repositionOnNewData={repositionOnNewData}
    />
  </ReactFlowProvider>
);

export default FlowChartDetails;
