import { BreadcrumbBack } from '@wk/components-react16/dist/breadcrumb-back/breadcrumb-back';
import { BreadcrumbItemLink } from '@wk/components-react16/dist/breadcrumb-item-link/breadcrumb-item-link';
import { BreadcrumbItem } from '@wk/components-react16/dist/breadcrumb-item/breadcrumb-item';
import { Breadcrumb } from '@wk/components-react16/dist/breadcrumb/breadcrumb';
import { ButtonFieldIcon } from '@wk/components-react16/dist/button-field-icon/button-field-icon';
import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import style from './RunOrchestrationBreadcrumb.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { setSidebarNavigated } from 'app/modal/ModalSlice';
import { SPINE_MENU_ITEMS } from 'utils/common-constants';

interface IRunOrchestrationBreadcrumb {
  moduleInformation: string;
}

export const RunOrchestrationBreadcrumb = ({
  moduleInformation,
}: IRunOrchestrationBreadcrumb) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateManageModules = () => {
    const slave = moduleInformation?.split('-')?.[0];
    const id = moduleInformation?.split('-')?.[1];
    navigate(`/manage-modules?slave=${slave}&id=${id}`);
    dispatch(setSidebarNavigated(SPINE_MENU_ITEMS.MANAGE_MODULES));
  };

  return (
    <div
      className={style['breadcrumb-container']}
      data-testid="RunOrchestrationBreadcrumb"
    >
      <Breadcrumb showLastChevron={false}>
        <BreadcrumbBack slot="breadcrumbBack">
          <ButtonFieldIcon>
            <button
              onClick={() => navigateManageModules()}
              data-testid="BackButton"
            >
              <Iconsvg name="arrow-left"></Iconsvg>
            </button>
          </ButtonFieldIcon>
        </BreadcrumbBack>
        <span slot="breadcrumbTooltipItem">Back to Installed Modules</span>
        <BreadcrumbItem slot="breadcrumbItem">
          <BreadcrumbItemLink isPreventDefault href="/">
            <span
              slot="breadcrumbItemLink"
              onClick={() => navigateManageModules()}
              data-testid="BackLabel"
            >
              Back to Installed Modules
            </span>
          </BreadcrumbItemLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};
