import { useAppDispatch, useAppSelector } from 'app/hooks';
import FlowChartDetails, {
  REPOSITION_AMOUNT,
} from 'components/FlowChartDetails/FlowChartDetails';
import OrchestrationHeader from 'components/OrchestrationHeader/OrchestrationHeader';
import OrchestrationPropertiesPanel from 'components/OrchestrationPropertiesPanel/OrchestrationPropertiesPanel';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import { IOrchestrationFullStep } from 'interfaces/orchestration.interface';
import { IOrchestrationInstancePanel } from 'interfaces/orchestrationDiagram/orchestration-diagram';
import { mapJobToTableData } from 'dto/jobToTable/jobToTable';
import { createOrchestrationInstanceSidePanelStep } from 'dto/orchestrationDiagram/orchestrationInstance';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  generateEdges,
  getSteps,
  processOrchestration,
} from './reactFlow/reactFlowHelpers';
import {
  setInitialPropertiesPanelValues,
  setCurrentJob,
  selectCurrentJob,
} from 'app/propertiesPanel/PropertiesPanelSlice';
import OrchestrationBanner from 'components/OrchestrationBanner/OrchestrationBanner';
import style from './OrchestrationDetails.module.scss';
import { SSE_STATUS } from 'utils/common-constants';

import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';
import { fullStepsToJobSteps } from 'dto/orchestrationDiagram/selectedOrchestration';
import { selectConnectionStatus } from 'app/auth/AuthSlice';
import { useLazyGetOrchestrationByIdQuery } from 'api/orchestrations/orchestrationApi';
// import { getOrchestrationById } from 'api/orchestrations/orchestrationsThunk';

const OrchestrationDetails = () => {
  const currentJob = useAppSelector(selectCurrentJob);
  const [nodes, setNodes] = useState<any[]>([]);
  const [edges, setEdges] = useState<any[]>([]);
  const allSteps = useRef<any[]>([]);
  const reUpdateCurrentJob = useRef<any>(true);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [selectedOrchestration, setSelectedOrchestration] =
    useState<IOrchestrationRow>();
  const [orchestrationInstance, setOrchestrationInstance] =
    useState<IOrchestrationInstancePanel>();
  const containerRef = useRef<HTMLDivElement>(null);
  const flowChartContainer = useRef<HTMLDivElement>(null);
  const connectionStatusState = useAppSelector(selectConnectionStatus);
  const [getOrchestrationById] = useLazyGetOrchestrationByIdQuery();
  useEffect(() => {
    if (connectionStatusState === SSE_STATUS.RESTORED) {
      getInfo();
    }
    return () => {};
  }, [connectionStatusState]);

  const setOrchestrationDetails = (orchestration: any) => {
    if (orchestration?.workflow?.nextStepGroup) {
      let steps: IOrchestrationFullStep[][] = [];
      let globalFailbackSteps: any = [];
      const containerWidth =
        flowChartContainer.current?.getBoundingClientRect().width ?? 1000;
      setNodes([]);
      setEdges([]);
      const formatedOrchestration = mapJobToTableData(orchestration);
      const orchestrationInstanceStep =
        createOrchestrationInstanceSidePanelStep(orchestration);
      steps = getSteps(
        orchestration.workflow.nextStepGroup,
        steps,
        false,
        'step'
      );
      globalFailbackSteps = getSteps(
        orchestration.workflow.failbackStepGroup,
        globalFailbackSteps,
        false,
        'globalFailback'
      );
      const { nodes, orchestrarionSteps } = processOrchestration(
        orchestration,
        containerWidth - containerWidth * 0.25,
        [...steps, ...globalFailbackSteps]
      );
      allSteps.current = orchestrarionSteps;
      const edges = generateEdges(steps);
      const failBackedges = generateEdges(globalFailbackSteps);
      setNodes(nodes);
      setOrchestrationInstance(orchestrationInstanceStep);
      setSelectedOrchestration(formatedOrchestration);
      setEdges([...edges, ...failBackedges]);
      dispatch(
        setInitialPropertiesPanelValues({
          orchestrationInstancePanelStep: orchestrationInstanceStep,
          propertiesPanelSteps: orchestrarionSteps,
        })
      );
    }
  };
  const getInfo = useCallback(async () => {
    const regex = /\/(\d+)(?:\?|$)/; // Matches the last numbers before either "?" or the end of the string
    const orchestrationId = location.pathname.match(regex)?.[1] ?? '';
    if (orchestrationId) {
      try {
        //TODO: Replace the following line with the commented lines
        // const orchestration = await dispatch(
        //   getOrchestrationById(orchestrationId)
        // )?.unwrap();
        const orchestration = await getOrchestrationById(
          orchestrationId
        )?.unwrap();
        console.log(orchestration);
        dispatch(setCurrentJob(orchestration));
      } catch (error) {
        consoleErrorMessage(error);
      }
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    getInfo();
  }, [location.pathname]);

  useEffect(() => {
    if (allSteps.current.length > 0) {
      reUpdateCurrentJob.current = false;
      dispatch(
        setCurrentJob({
          ...currentJob,
          steps: fullStepsToJobSteps(allSteps.current),
        })
      );
    }
    return () => {
      dispatch(setCurrentJob({} as any));
    };
  }, [allSteps.current]);

  useEffect(() => {
    if (
      currentJob &&
      Object.keys(currentJob).length > 0 &&
      reUpdateCurrentJob.current === true
    ) {
      setOrchestrationDetails(currentJob);
    } else {
      reUpdateCurrentJob.current = true;
    }
  }, [currentJob]);

  return (
    <>
      <div
        data-testid="OrchestrationDetails"
        ref={containerRef}
        className={style['orchestration-details']}
      >
        <section className={style['orchestration-details__main__section']}>
          <CsbErrorBoundary>
            <OrchestrationBanner
              selectedOrchestration={selectedOrchestration}
            />
          </CsbErrorBoundary>
          <div className={style['orchestration-details__header']}>
            <CsbErrorBoundary>
              <OrchestrationHeader
                selectedOrchestration={selectedOrchestration}
              />
            </CsbErrorBoundary>
          </div>
          <div
            className={style['orchestration-details__flow-chart']}
            data-testid="FlowChartDetails"
            ref={flowChartContainer}
          >
            <CsbErrorBoundary>
              <FlowChartDetails
                nodes={nodes}
                edges={edges}
                repositionOnNewData={undefined}
              />
            </CsbErrorBoundary>
          </div>
        </section>
        {orchestrationInstance && (
          <CsbErrorBoundary>
            <OrchestrationPropertiesPanel containerRef={containerRef} />
          </CsbErrorBoundary>
        )}
      </div>
    </>
  );
};

export default OrchestrationDetails;
