import { STEP_STATUSES } from 'utils/common-constants';
import { mapOrchestrationStep } from 'dto/orchestrationDiagram/orchestrationSteps';
import {
  IOrchestrationServer,
  IOrchestrationFullStep,
  IOrchestrationStepGroup,
  IStepInformation,
} from 'interfaces/orchestration.interface';
import {
  IEdges,
  IOrchestrationNode,
  IOrchestrationSidePanelSteps,
  stepTypes,
} from 'interfaces/orchestrationDiagram/orchestration-diagram';
import { DateUtils } from 'utils/dateUtils/DateUtils';
import { Useuid } from 'utils/hooks/useUid';
import { IServerWorkflow } from 'interfaces/workflow.interface';

const X_GAP = 50;
const NODE_WIDTH = 300 + X_GAP;
const NODE_HEIGHT = 80;
const Y_GAP = 30;

//get initial steps and tag them with necesary properties
export const getSteps = (
  stepsGroup: any,
  acc: IOrchestrationFullStep[][],
  isNestedStep: boolean,
  stepType: stepTypes,
  nestGroupId?: any
): IOrchestrationFullStep[][] => {
  if (stepsGroup) {
    const newStep = stepsGroup.steps?.map((step: any) => ({
      ...step,
      isNestedStep,
      stepType,
      nestGroupId:
        step.nestGroupId?.length > 0
          ? [...step.nestGroupId, nestGroupId].flat()
          : [nestGroupId].flat(),
    }));
    acc.push(newStep);
    getNestedAndFailbackSteps(newStep, acc, isNestedStep, stepType);
    getSteps(
      stepsGroup.nextStepGroup,
      acc,
      isNestedStep,
      stepType,
      nestGroupId
    );
  }
  return acc;
};

const getNestedAndFailbackSteps = (
  newStep: any,
  acc: IOrchestrationFullStep[][],
  isNestedStep: boolean,
  stepType: stepTypes
) => {
  newStep?.map((step: any) => {
    const nestedGroupId =
      step.nestGroupId?.length > 0 &&
      step.nestGroupId.find((groupId: string) => groupId !== undefined)
        ? [...step.nestGroupId, step.workflowStepId.toString()]
        : [step.workflowStepId.toString()];
    if (step.failbackStepGroup) {
      getSteps(
        step.failbackStepGroup,
        acc,
        isNestedStep,
        stepType === 'globalFailback' ? stepType : 'failback',
        nestedGroupId
      );
    }
    if (step.nestedStepGroup) {
      getSteps(step.nestedStepGroup, acc, true, stepType, nestedGroupId);
    }
  });
};

export const getStepsWithGroups = (workflow: any) => {
  const result: any = [];

  // Helper function to recursively traverse the steps and groups
  const traverseSteps = (stepGroup: any) => {
    if (!stepGroup) {
      return;
    }

    for (const step of stepGroup.steps) {
      const stepInfo = {
        stepTitle: step.stepTitle,
        workflowStepId: step.workflowStepId,
        stepIndex: step.stepIndex,
        workflowStepGroupId: step.workflowStepGroupId,
        childs: [
          step.nestedStepGroup?.steps?.map((x: any) => ({
            workflowStepId: x.workflowStepId,
            stepIndex: x.stepIndex,
            stepTitle: x.stepTitle,
            workflowStepGroupId: x.workflowStepGroupId,
          })),
          stepGroup.nextStepGroup?.steps?.map((x: any) => ({
            workflowStepId: x.workflowStepId,
            stepIndex: x.stepIndex,
            stepTitle: x.stepTitle,
            workflowStepGroupId: x.workflowStepGroupId,
          })),
          step.failbackStepGroup?.steps?.map((x: any) => ({
            workflowStepId: x.workflowStepId,
            stepIndex: x.stepIndex,
            stepTitle: x.stepTitle,
            workflowStepGroupId: x.workflowStepGroupId,
          })),
        ]
          .filter((x) => x !== undefined)
          .flat(),
      };
      result.push(stepInfo);

      // Recursively traverse nested step groups
      traverseSteps(step.nestedStepGroup);

      // Recursively traverse failback step groups
      traverseSteps(step.failbackStepGroup);
    }

    // Recursively traverse next step groups
    traverseSteps(stepGroup.nextStepGroup);
  };

  traverseSteps(workflow.nextStepGroup);

  return result;
};

export function processOrchestration(
  orchestration: Partial<IOrchestrationServer> | Partial<IServerWorkflow>,
  containerWidth = 1000,
  steps: IOrchestrationFullStep[][]
): {
  nodes: IOrchestrationNode[];
  orchestrarionSteps: IOrchestrationSidePanelSteps[];
} {
  if (!steps || !steps.length) {
    return { nodes: [], orchestrarionSteps: [] };
  }
  let stepsParamsData: any = undefined;

  if ('steps' in orchestration) {
    stepsParamsData = getStepsParamsData(orchestration.steps);
  }
  const stepsSideBarData = generateStepsSideBarData(steps, stepsParamsData);

  const orchestrationContext = {
    currentY: 0,
    nodeTracker: {},
    processedNodes: [],
    stepsParamsData,
  };

  processWorkflowSteps(orchestration, orchestrationContext, containerWidth);
  return {
    nodes: orchestrationContext.processedNodes,
    orchestrarionSteps: stepsSideBarData,
  };
}

function processWorkflowSteps(
  orchestration: Partial<IOrchestrationServer> | Partial<IServerWorkflow> | any,
  context: any,
  containerWidth: number
) {
  const { processIndividualStepGroup } =
    initializeStepProcessingFunctions(context);

  if (orchestration.workflow?.nextStepGroup || orchestration.nextStepGroup) {
    const workflow =
      orchestration.workflow?.nextStepGroup ?? orchestration.nextStepGroup;
    processIndividualStepGroup(workflow, containerWidth / 2 - 150, 0, 'step');
    context.currentY += NODE_HEIGHT + Y_GAP;
  }
  if (
    orchestration.workflow?.failbackStepGroup ||
    orchestration.failbackStepGroup
  ) {
    const workflow =
      orchestration.workflow?.failbackStepGroup ??
      orchestration.failbackStepGroup;
    processIndividualStepGroup(
      workflow,
      containerWidth / 2 - 150,
      context.currentY + NODE_HEIGHT + Y_GAP,
      'globalFailback'
    );
  }
}

function initializeStepProcessingFunctions(context: any) {
  function calculateGroupWidth(stepGroup: any): number {
    if (!stepGroup || !stepGroup.steps) {
      return 0;
    }
    const width = stepGroup.steps?.length * NODE_WIDTH;
    let maxWidthNestedFailback = 0;
    stepGroup.steps?.map((step: any) => {
      const nestedWidth = step?.nestedStepGroup
        ? calculateGroupWidth(step.nestedStepGroup)
        : 0;
      const failbackWidth = step?.failbackStepGroup
        ? calculateGroupWidth(step.failbackStepGroup)
        : 0;
      maxWidthNestedFailback = Math.max(
        maxWidthNestedFailback,
        nestedWidth,
        failbackWidth
      );
    });
    return Math.max(width, maxWidthNestedFailback);
  }

  function processIndividualStepGroup(
    stepGroup: IOrchestrationStepGroup,
    startX: number,
    startY: number,
    stepType: stepTypes
  ) {
    if (!stepGroup || !stepGroup.steps) {
      return startX;
    }

    context.currentY = Math.max(context.currentY, startY);
    let totalChildrenWidth = 0;

    if (stepGroup.steps?.length > 1) {
      for (const step of stepGroup.steps) {
        if (step.nestedStepGroup || step.failbackStepGroup) {
          totalChildrenWidth += calculateGroupWidth({ steps: [step] });
        } else {
          totalChildrenWidth += NODE_WIDTH;
        }
      }
    }

    let { maxXPosition } = processAndPositionSteps(
      stepGroup,
      startX,
      startY,
      stepType,
      totalChildrenWidth
    );

    if (stepGroup.nextStepGroup) {
      const nextGroupX = processIndividualStepGroup(
        stepGroup.nextStepGroup,
        maxXPosition,
        context.currentY + NODE_HEIGHT + Y_GAP,
        stepType
      );
      maxXPosition = Math.max(maxXPosition, nextGroupX);
    }

    return maxXPosition;
  }

  function createNodeData(
    step: any,
    x: number,
    y: number,
    stepParamsData: any,
    stepType: stepTypes
  ) {
    return {
      id: step.workflowStepId.toString(),
      type: 'orchestrationFlowElement',
      data: {
        name: step.stepTitle || '',
        id: step.workflowStepId.toString(),
        moduleName: step.module?.name,
        description: step.module?.description || '',
        duration: getNodeDuration(stepParamsData),
        status: stepParamsData?.status || 'NOT_STARTED',
        index: step.stepIndex,
        stepType,
        version: step.module?.version || 'N/A',
        isConditional: !!step.stepCondition,
      },
      position: { x, y },
    };
  }

  const processAndPositionSteps = (
    stepGroup: any,
    currentX: number,
    startY: number,
    stepType: stepTypes,
    totalChildrenWidth: number
  ): any => {
    const maxXPosition = currentX;
    let previousY;
    for (const step of stepGroup.steps) {
      if (context.nodeTracker[step.workflowStepId]) {
        continue; // Skip already processed nodes
      }

      const stepWidth = calculateGroupWidth({ steps: [step] });

      previousY = startY;

      // If the current step has a next step group with multiple children, we need to center them
      let offset = 0;
      if (totalChildrenWidth > NODE_WIDTH && stepGroup.steps?.length > 1) {
        offset = (stepWidth - totalChildrenWidth) / 2;
      }

      const stepX = offset + currentX;

      if (step.nestedStepGroup) {
        processIndividualStepGroup(
          step.nestedStepGroup,
          stepX,
          previousY + NODE_HEIGHT + Y_GAP,
          'step'
        );
      }

      if (step.failbackStepGroup) {
        processIndividualStepGroup(
          step.failbackStepGroup,
          stepX,
          previousY + NODE_HEIGHT + Y_GAP,
          'failback'
        );
      }
      // Create and push node data
      const stepParamsData = context.stepsParamsData?.[step.workflowStepId];
      context.processedNodes.push(
        createNodeData(step, stepX, startY, stepParamsData, stepType)
      );
      // Mark the step as processed
      context.nodeTracker[step.workflowStepId] = true;

      if (stepGroup.steps?.length > 1) {
        currentX += stepWidth;
      } else {
        currentX = maxXPosition; // This ensures steps are placed correctly after nested or failback groups
      }
    }
    return { currentX, maxXPosition };
  };
  return { processIndividualStepGroup };
}

const getNodeDuration = (stepParamsData: any) => {
  return stepParamsData?.status === STEP_STATUSES.NOT_STARTED
    ? '-'
    : DateUtils.getFormattedDiff(
        stepParamsData?.startTime,
        stepParamsData?.endTime || new Date().toISOString()
      ) || '1s';
};

const getStepsParamsData = (
  steps: IStepInformation[] | undefined
): { [key: number]: IStepInformation } | undefined => {
  if (steps === undefined || steps === null) {
    return undefined;
  }
  const stepsObject: { [key: number]: IStepInformation } = {};
  steps.forEach((step: any) => {
    stepsObject[step.workflowStepId] = step;
  });
  return stepsObject;
};

const generateStepsSideBarData = (
  steps: IOrchestrationFullStep[][],
  stepsObject: any[] | undefined
): any[] => {
  const stepsSideBarData: any = [];
  steps.forEach((stepGroup: IOrchestrationFullStep[]) =>
    stepGroup.forEach((step: IOrchestrationFullStep) =>
      stepsSideBarData.push(
        mapOrchestrationStep(step, stepsObject?.[step.workflowStepId])
      )
    )
  );
  return stepsSideBarData;
};

export const generateEdges = (
  stepGroups: IOrchestrationFullStep[][]
): IEdges[] => {
  const edges: IEdges[][] = [];
  stepGroups.forEach((group, index) => {
    group.forEach((currentStep: IOrchestrationFullStep) => {
      if (!currentStep) {
        return;
      }
      const nextFailBackGroup = currentStep.failbackStepGroup?.steps;
      const nextNestedGroup = currentStep.nestedStepGroup?.steps;
      const nextGroup = findNextGroup(stepGroups, index, currentStep);

      if (nextFailBackGroup?.length) {
        edges.push(
          setEdgesBasedOnStepGroup(nextFailBackGroup, currentStep, index, false)
        );
      } else if (nextNestedGroup?.length) {
        edges.push(
          setEdgesBasedOnStepGroup(nextNestedGroup, currentStep, index, false)
        );
      } else if (nextGroup?.length) {
        edges.push(
          setEdgesBasedOnStepGroup(nextGroup, currentStep, index, true)
        );
      }
    });
  });
  return edges.flat();
};

const findNextGroup = (
  stepGroups: IOrchestrationFullStep[][],
  index: number,
  currentStep: IOrchestrationFullStep
): IOrchestrationFullStep[] | undefined => {
  return stepGroups.slice(index + 1).find((steps) => {
    return steps.some((step) => {
      const nestGroupIds = [...(step.nestGroupId || [])].reverse();
      return (
        (!step.isNestedStep && nestGroupIds[0] === undefined) ||
        (currentStep.nestGroupId?.includes(nestGroupIds[0]) &&
          currentStep.workflowStepId > step.workflowStepId &&
          compareStepIndices(currentStep, step))
      );
    });
  });
};

export function setEdgesBasedOnStepGroup(
  stepsGroup: IOrchestrationFullStep[],
  currentStep: IOrchestrationFullStep,
  stepIndex: number,
  avoidNested: boolean
): IEdges[] {
  return stepsGroup
    .map((step: IOrchestrationFullStep) => {
      if (
        avoidNested === true &&
        step.isNestedStep === true &&
        step.failbackStepGroup !== null
      ) {
        return null;
      } else if (
        currentStep.workflowStepId?.toString() !==
        step.workflowStepId?.toString()
      ) {
        return {
          id: `edge-${Useuid()}`,
          source: currentStep.workflowStepId?.toString(),
          target: step.workflowStepId?.toString(),
          sourceHandle: 'bottom',
          type: 'smartEdges',
          className: 'csb-custom-edge',
        } as IEdges;
      } else {
        return null;
      }
    })
    .filter((edge) => edge != null) as IEdges[];
}

function compareStepIndices(
  currentStep: IOrchestrationFullStep,
  step: IOrchestrationFullStep
): boolean {
  const currentStepIndex = currentStep.stepIndex;
  const stepIndex = step.stepIndex;

  if (currentStepIndex.includes('E') && !stepIndex.includes('E')) {
    return true;
  }

  if (!currentStepIndex.includes('E') && stepIndex.includes('E')) {
    return true;
  }

  const currentStepIndexNumber = currentStepIndex.replace('E', '');
  const stepIndexNumber = stepIndex.replace('E', '');

  if (
    !isNaN(Number(currentStepIndexNumber)) &&
    !isNaN(Number(stepIndexNumber))
  ) {
    return Number(currentStepIndexNumber) < Number(stepIndexNumber);
  } else {
    return true;
  }
}
