import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import style from './RunOrchestrations.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';

import {
  RUN_ORCHESTRATIONS_TABLE_COLUMNS,
  VIEW_OPTIONS,
} from './RunOrchestrations.constants';
import {
  clearState,
  searchFilter,
  selectSelectedWorkflow,
  selectWorkflows,
  setSelectedWorkflow,
} from 'app/runOrchestrations/RunOrchestrationsSlice';

import {
  IWorkflowProduct,
  IWorkflow,
} from 'interfaces/runOrchestration/workflow-row';
import { RunOrchestrationBanner } from 'components/RunOrchestrationBanner/RunOrchestrationBanner';
import { selectConnectionStatus, selectUserRole } from 'app/auth/AuthSlice';
import { SSE_STATUS, USERS_ROLES } from 'utils/common-constants';
import { SplitView } from '../../components/RunOrchestrationsViews/SplitView/SplitView';
import { ColumnView } from 'components/RunOrchestrationsViews/ColumnView/ColumnView';
import { ListView } from 'components/RunOrchestrationsViews/ListView/ListView';
import {
  MODULE_DATA,
  MODULE_DISPLAY_PARAM,
  MODULE_NAME_PARAM,
} from 'pages/ManageModules/ManageModules.constants';
import {
  checkAndScrollToItem,
  consoleErrorMessage,
} from 'utils/commonFunctions/CommonFunctions';
import { fetchWorkflows } from 'api/workflows/workflowsThunk';
import { IServerWorkflow } from 'interfaces/workflow.interface';

const RunOrchestrations = () => {
  const dispatch = useAppDispatch();
  const workflowsState: IWorkflow[] | IWorkflowProduct[] =
    useAppSelector(selectWorkflows);
  const userRole = useAppSelector(selectUserRole);
  const selectedWorkflow = useAppSelector(selectSelectedWorkflow);
  const [workflowData, setWorkflowData] = useState<IServerWorkflow[]>([]);
  const [tableColumns, setTableColumns] = useState<any>(
    RUN_ORCHESTRATIONS_TABLE_COLUMNS
  );
  const [queryParams, setQueryParams] = useState(
    new URLSearchParams(window.location.search)
  );
  const [wasRedirectedFromModulesScreen, setWasRedirectedFromModuleScreen] =
    useState<boolean>(false);
  const queryDisplay = queryParams.get(MODULE_DISPLAY_PARAM);
  const queryName = queryParams.get(MODULE_NAME_PARAM);
  const [storedModuleData] = useState<string | null>(
    queryParams.get(MODULE_DATA)
  );
  const queryModuleData = queryParams.get(MODULE_DATA) || storedModuleData;
  const [currentView, setCurrentView] = useState(
    queryDisplay === VIEW_OPTIONS.COLUMN_VIEW
      ? VIEW_OPTIONS.COLUMN_VIEW
      : VIEW_OPTIONS.LIST_VIEW
  );
  const connectionStatusState = useAppSelector(selectConnectionStatus);

  const getInfo = useCallback(async () => {
    try {
      const data = await dispatch(fetchWorkflows())?.unwrap();
      setWorkflowData(data);
    } catch (error) {
      console.error(consoleErrorMessage(error));
    }
  }, [dispatch]);

  useEffect(() => {
    if (queryName) {
      const matchingWorkflow = workflowsState.find(
        (workflow) => workflow.name === queryName
      );
      setWasRedirectedFromModuleScreen(true);
      if (matchingWorkflow) {
        dispatch(setSelectedWorkflow(matchingWorkflow));
        checkAndScrollToItem(`#workflow-name-${matchingWorkflow.id}-cell`);
        window.history.replaceState(null, '', '/run-orchestration');
        setQueryParams(new URLSearchParams(window.location.search));
      }
    }
  }, [workflowsState]);

  useEffect(() => {
    if (wasRedirectedFromModulesScreen && selectedWorkflow) {
      checkAndScrollToItem(`#workflow-name-${selectedWorkflow.id}-cell`);
    }
  }, [currentView, wasRedirectedFromModulesScreen]);

  useEffect(() => {
    getInfo();
    return () => {
      dispatch(setSelectedWorkflow(null));
      dispatch(searchFilter(null));
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (connectionStatusState === SSE_STATUS.RESTORED) {
      getInfo();
    }
  }, [connectionStatusState]);

  useEffect(() => {
    if (currentView === VIEW_OPTIONS.LIST_VIEW) {
      setTableColumns(sliceTableBasedOnUserRole(userRole));
    }
    if (currentView === VIEW_OPTIONS.SPLIT_VIEW) {
      const table = sliceTableBasedOnUserRole(userRole);
      if (
        !userRole.includes(USERS_ROLES.DEVELOPER) &&
        !userRole.includes(USERS_ROLES.ADMIN)
      ) {
        const updatedTableColumns = [...table.slice(0, 2)];
        setTableColumns(updatedTableColumns);
      } else {
        const updatedTableColumns = [...table.slice(0, 2), ...table.slice(-1)];
        setTableColumns(updatedTableColumns);
      }
    }
    if (currentView === VIEW_OPTIONS.COLUMN_VIEW) {
      const table = sliceTableBasedOnUserRole(userRole);
      let updatedTableColumns;
      if (
        !userRole.includes(USERS_ROLES.DEVELOPER) &&
        !userRole.includes(USERS_ROLES.ADMIN)
      ) {
        updatedTableColumns = [...table.slice(0, 1), ...table.slice(-1)];
        setTableColumns(updatedTableColumns);
      } else {
        updatedTableColumns = [...table.slice(0, 1), ...table.slice(-2)];
        setTableColumns(updatedTableColumns);
      }
      if (updatedTableColumns.length > 1) {
        updatedTableColumns[1] = {
          ...updatedTableColumns[1],
          maxWidth: undefined,
        };
      }
    }
  }, [currentView, userRole]);

  const getRowId = useMemo(() => {
    return (params: any) => {
      return params.data.id;
    };
  }, []);

  return (
    <div
      data-testid="RunOrchestrations"
      className={style['run-orchestrations']}
    >
      <section className={style['run-orchestrations-banner']}>
        <CsbErrorBoundary>
          <RunOrchestrationBanner
            setCurrentView={setCurrentView}
            currentView={currentView}
            wasRedirectedFromModulesScreen={wasRedirectedFromModulesScreen}
            moduleInformation={queryModuleData || undefined}
          />
        </CsbErrorBoundary>
      </section>

      <section className={style['run-orchestrations-div-table']}>
        {currentView === VIEW_OPTIONS.LIST_VIEW && (
          <ListView
            serverWorkflowsData={workflowData ?? []}
            getRowId={getRowId}
            tableColumns={tableColumns}
          />
        )}
        {currentView === VIEW_OPTIONS.SPLIT_VIEW && (
          <SplitView
            serverWorkflowsData={workflowData ?? []}
            getRowId={getRowId}
            tableColumns={tableColumns}
          />
        )}
        {currentView === VIEW_OPTIONS.COLUMN_VIEW && (
          <ColumnView
            serverWorkflowsData={workflowData ?? []}
            getRowId={getRowId}
            tableColumns={tableColumns}
          />
        )}
        <div className={style['run-orchestrations-bottom-border']}></div>
      </section>
    </div>
  );
};

export default RunOrchestrations;

const sliceTableBasedOnUserRole = (userRole: any) => {
  if (
    !userRole.includes(USERS_ROLES.DEVELOPER) &&
    !userRole.includes(USERS_ROLES.ADMIN)
  ) {
    return RUN_ORCHESTRATIONS_TABLE_COLUMNS.slice(0, 5);
  }
  return RUN_ORCHESTRATIONS_TABLE_COLUMNS;
};
