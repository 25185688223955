import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './CellModuleServerName.module.scss';
import { IModuleInstancesRow } from 'interfaces/modules/module.interface';

const CellModuleServerName = (props: { data: IModuleInstancesRow }) => {
  const rowData = props.data;

  return (
    <CsbErrorBoundary>
      <div data-testid="CellModuleServerName">
        <span className={style['bold']}>
          {rowData.tenant ? `${rowData.tenant} - ` : ''}
        </span>
        <span>{rowData.name}</span>
      </div>
    </CsbErrorBoundary>
  );
};

export default CellModuleServerName;
