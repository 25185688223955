import { createAsyncThunk } from '@reduxjs/toolkit';
import { IAvailableModuleServer } from 'interfaces/modules/module.interface';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';

export const fetchAvailableModules = createAsyncThunk(
  'manageModules/fetchAvailableModules',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/csb/modules/available', {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: IAvailableModuleServer = await response.json();
      return data;
    } catch (error) {
      consoleErrorMessage(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchAvailableModulesWithoutLoading = createAsyncThunk(
  'manageModules/fetchAvailableModulesWithoutLoading',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/csb/modules/available', {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: IAvailableModuleServer = await response.json();
      return data;
    } catch (error) {
      consoleErrorMessage(error);
      return rejectWithValue(error);
    }
  }
);
