import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './CellOptions.module.scss';
import { DropdownMoreButton } from '@wk/components-react16/dist/dropdown-more-button/dropdown-more-button';
import {
  Dropdown,
  DropdownList,
  DropdownListItem,
  Iconsvg,
} from '@wk/components-react16';
import { useState } from 'react';
import {
  hasPermission,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  IWorkflow,
  IWorkflowProduct,
  RUN_ORCHESTRARIONS_STATUS,
} from 'interfaces/runOrchestration/workflow-row';
import {
  useLazyGetWorkflowExportQuery,
  useToggleDeactivateMutation,
} from 'api/workflows/workflows';
import { extractWorkflowStatus } from 'dto/runOrchestration/workflowToTable';
import {
  selectSelectedWorkflow,
  setSelectedWorkflow,
} from 'app/runOrchestrations/RunOrchestrationsSlice';
import { selectUserPermissions } from 'app/auth/AuthSlice';
import { USER_PERMISSIONS } from 'utils/common-constants';
import { openModal } from 'app/modal/ModalSlice';
import { MODAL_NAMES } from 'components/Modals/modal-list';

interface ICellOptions {
  data?: IWorkflow | IWorkflowProduct;
}

export type CellOptionsDropdownItems =
  | 'EDIT'
  | 'EXPORT'
  | 'TOGGLE_DEACTIVATE'
  | 'DELETE';

const placementOptions: any = {
  positions: ['bottom-left', 'top-left', 'top-right', 'bottom-right'],
  offsets: {
    'top-right': {
      topOffset: -2,
      leftOffset: 0,
    },
    'bottom-right': {
      topOffset: 2,
      leftOffset: 0,
    },
    'bottom-left': {
      topOffset: -5,
      leftOffset: 0,
    },
    'top-left': {
      topOffset: 5,
      leftOffset: 0,
    },
  },
};

const CellOptions = ({ data }: ICellOptions) => {
  const [displayList, setDisplayList] = useState<boolean | undefined>(false);
  const dispatch = useAppDispatch();
  const [getWorkflowExport] = useLazyGetWorkflowExportQuery();
  const [toggleDeactivate] = useToggleDeactivateMutation();
  const userPermission = useAppSelector(selectUserPermissions);
  const [toggleDeactivateLabel, setToggleDeactivateLabel] = useState<string>(
    data?.status === RUN_ORCHESTRARIONS_STATUS.ACTIVE
      ? 'Deactivate'
      : 'Activate'
  );
  const selectedWorkflow = useAppSelector(selectSelectedWorkflow);
  const clickOptions: Record<CellOptionsDropdownItems, () => any> = {
    EDIT: () => handleEdit(data?.isProduct ?? false),
    EXPORT: () => handleExport(data?.name || ''),
    TOGGLE_DEACTIVATE: () => handleToggleDeactivate(),
    DELETE: () =>
      showToastAndClose('warning', 'deleteOrchestration', () => {}, dispatch, {
        id: data?.isProduct ? (data as IWorkflowProduct)?.parentId : data?.id,
        productId: data?.isProduct
          ? (data as IWorkflowProduct)?.productId
          : undefined,
      }),
  };

  const handleClick = (option: CellOptionsDropdownItems) => {
    const selectedOption = clickOptions[option];
    selectedOption();
  };

  const handleEdit = (isProduct: boolean) => {
    if (isProduct) {
      dispatch(
        openModal({
          id: '1',
          componentName: MODAL_NAMES.EDIT_PRODUCT,
          title: 'Update product details',
          footerName: 'cancel-next',
          display: true,
          componentProps: { data: { wizardStartingIndex: 0 } },
          width: '1500px',
        })
      );
    }
  };

  const handleExport = async (name: string) => {
    try {
      const response = await getWorkflowExport(data?.name || '');
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/xml' })
      );
      const file = document.createElement('a', { is: 'test' });
      file.href = url;
      file.download = `${name}.xml`;
      document.body.appendChild(file);
      file.click();
      file.remove();
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggleDeactivate = async () => {
    if (data?.id) {
      try {
        const result = await toggleDeactivate(data.id).unwrap();
        const resultStatus = extractWorkflowStatus(result.disabled);
        const toastDisplayText =
          resultStatus === RUN_ORCHESTRARIONS_STATUS.ACTIVE
            ? 'Orchestration successfully activated'
            : 'Orchestration deactivated successfully';
        setToggleDeactivateLabel(
          resultStatus === RUN_ORCHESTRARIONS_STATUS.ACTIVE
            ? 'Deactivate'
            : 'Activate'
        );
        showToastAndClose('success', 'informationToast', () => {}, dispatch, {
          text: toastDisplayText,
        });

        dispatch(setSelectedWorkflow({ ...data, status: resultStatus } as any));
      } catch (error) {
        console.error(error);
        showToastAndClose('error', 'informationToast', () => {}, dispatch, {
          text: (error as any)?.data ?? 'No error message available',
        });
      }
    }
  };

  return (
    <CsbErrorBoundary>
      <div
        className={style['cell-options']}
        data-testid="CellOptions"
        onMouseLeave={() => setDisplayList(false)}
      >
        <div
          className={style['dropdown']}
          data-testid="Dropdown"
          onClick={() => setDisplayList(true)}
        >
          <DropdownMoreButton
            mode="blue"
            buttonId={`options-${data?.id}`}
          ></DropdownMoreButton>
          <Dropdown
            menuMode="application"
            targetSelector={`#options-${data?.id}`}
            srLabelledby="options"
            controlMode="controlled"
            isOpen={displayList}
            placementOptions={placementOptions}
          >
            <div slot="dropdownListItem" data-testid="DropdownList">
              <DropdownList menuMode="application">
                <DropdownListItem
                  onItemClick={() => handleClick('EDIT')}
                  slot="dropdownListItem"
                  isDisabled={
                    selectedWorkflow?.id !== data?.id ||
                    (data?.isProduct &&
                      !hasPermission(userPermission, [
                        USER_PERMISSIONS.PRODUCT_EDIT,
                      ])) ||
                    (!data?.isProduct &&
                      !hasPermission(userPermission, [
                        USER_PERMISSIONS.ORCHESTRATION_EDIT,
                      ]))
                  }
                  data-testid="EditBtn"
                >
                  <Iconsvg name="pencil"></Iconsvg>
                  Edit
                </DropdownListItem>
                <DropdownListItem
                  onItemClick={() => handleClick('EXPORT')}
                  slot="dropdownListItem"
                  isDisabled={
                    selectedWorkflow?.id !== data?.id ||
                    data?.isProduct ||
                    !hasPermission(userPermission, [
                      USER_PERMISSIONS.ORCHESTRATION_EXPORT,
                    ])
                  }
                >
                  <Iconsvg name="export"></Iconsvg>Export
                </DropdownListItem>
                <DropdownListItem
                  onItemClick={() => handleClick('TOGGLE_DEACTIVATE')}
                  slot="dropdownListItem"
                  isDisabled={
                    selectedWorkflow?.id !== data?.id ||
                    data?.isProduct ||
                    selectedWorkflow?.id !== data?.id ||
                    !hasPermission(userPermission, [
                      USER_PERMISSIONS.ORCHESTRATION_DEACTIVATE,
                    ])
                  }
                >
                  <Iconsvg name="stop-sign"></Iconsvg>
                  {`${toggleDeactivateLabel}`}
                </DropdownListItem>
                <DropdownListItem
                  onItemClick={() => handleClick('DELETE')}
                  slot="dropdownListItem"
                  isDisabled={
                    selectedWorkflow?.id !== data?.id ||
                    (data?.isProduct &&
                      !hasPermission(userPermission, [
                        USER_PERMISSIONS.PRODUCT_REMOVE,
                      ])) ||
                    (!data?.isProduct &&
                      !hasPermission(userPermission, [
                        USER_PERMISSIONS.ORCHESTRATION_REMOVE,
                      ]))
                  }
                >
                  <Iconsvg name="trash"></Iconsvg>Delete
                </DropdownListItem>
              </DropdownList>
            </div>
          </Dropdown>
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

export default CellOptions;
