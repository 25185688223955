import { useAppDispatch, useAppSelector } from 'app/hooks';
import { IToast } from 'interfaces/toasts';
import {
  ButtonField,
  Notification,
  NotificationActions,
} from '@wk/components-react16';
import { removeToast } from 'app/toast/toastSlice';
import {
  capitalizeFirstLetter,
  consoleErrorMessage,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useState, useRef, useEffect } from 'react';
import {
  useRemoveAllModulesMutation,
  useRestartAllModulesMutation,
  useStopAllModulesMutation,
} from 'api/manageModules/modulesApi';
import { selectSelectedModule } from 'app/manageModules/ManageModulesSlice';
import { IModuleServer } from 'interfaces/modules/module.interface';
import { IServerWorkflow } from 'interfaces/workflow.interface';

export interface IInlineActionModuleButtonsToast {
  item: IToast;
}

export enum INLINE_ACTION_MODULE_TYPES {
  'stop' = 'stop',
  'restart' = 'restart',
  'remove' = 'remove',
}

export const InlineActionModuleButtonsToast = ({
  item,
}: IInlineActionModuleButtonsToast) => {
  const dispatch = useAppDispatch();
  const selectedModule: IModuleServer = useAppSelector(selectSelectedModule);
  const [stopAllModulesInstances] = useStopAllModulesMutation();
  const [restartAllModuleInstances] = useRestartAllModulesMutation();
  const [removeAllModuleInstances] = useRemoveAllModulesMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [openStatus, setOpenStatus] = useState(true);
  const container = useRef<HTMLDivElement>(null);
  const isFirstClick = useRef(false);
  const actionText =
    item?.additionalData?.id === INLINE_ACTION_MODULE_TYPES.stop
      ? 'Are you sure you want to stop this module?'
      : item?.additionalData?.id === INLINE_ACTION_MODULE_TYPES.restart
      ? 'Are you sure you want to restart this module?'
      : 'Are you sure you want to remove this module?';

  const handleAction = async () => {
    setIsLoading(true);
    if (item?.additionalData?.id === INLINE_ACTION_MODULE_TYPES.stop) {
      try {
        await stopAllModulesInstances({
          moduleId: selectedModule.id,
          slave: selectedModule.slave,
        }).unwrap();
        showToastAndClose(
          'success',
          'informationToast',
          (...args) => {},
          dispatch,
          {
            text: `Your module has been stopped. It may take a while for the status to be updated.`,
          }
        );
      } catch (error) {
        console.error('Error while stopping module', error);
        showToastAndClose(
          'error',
          'informationToast',
          (...args) => {},
          dispatch,
          {
            text:
              consoleErrorMessage(error) ??
              'Unable to stop module: An unknown error occurred.',
          }
        );
      }
    } else if (
      item?.additionalData?.id === INLINE_ACTION_MODULE_TYPES.restart
    ) {
      try {
        await restartAllModuleInstances({
          moduleId: selectedModule.id,
          slave: selectedModule.slave,
        }).unwrap();
        showToastAndClose(
          'success',
          'informationToast',
          (...args) => {},
          dispatch,
          {
            text: `Your Module has been restarted.`,
          }
        );
      } catch (error) {
        console.error('Error while restarting module', error);
        showToastAndClose(
          'error',
          'informationToast',
          (...args) => {},
          dispatch,
          {
            text:
              consoleErrorMessage(error) ??
              'Unable to restart module: An unknown error occurred.',
          }
        );
      }
    } else if (item.additionalData?.id === INLINE_ACTION_MODULE_TYPES.remove) {
      try {
        const response = await removeAllModuleInstances({
          moduleId: selectedModule.id,
          slave: selectedModule.slave,
        }).unwrap();
        if (response.length && response.length > 0) {
          const activeWorkflows = response
            .map((workflow: IServerWorkflow) => workflow.name)
            .join(', ');
          showToastAndClose(
            'error',
            'informationToast',
            (...args) => {},
            dispatch,
            {
              text: `Unable to remove module: Orchestrations using this module: ${activeWorkflows}.`,
            }
          );
        } else {
          showToastAndClose(
            'success',
            'informationToast',
            (...args) => {},
            dispatch,
            {
              text: `Your module has been removed.`,
            }
          );
        }
      } catch (error) {
        console.error('Error while removing module', error);
        showToastAndClose(
          'error',
          'informationToast',
          (...args) => {},
          dispatch,
          {
            text:
              consoleErrorMessage(error) ??
              'Unable to remove module: An unknown error occurred.',
          }
        );
      }
    }
    dispatch(removeToast(item.id));
    setIsLoading(false);
  };

  const handleCancel = () => {
    setOpenStatus(false);
  };

  const handleClickOutside = (event: any) => {
    if (
      openStatus &&
      container.current &&
      !container.current.contains(event.target) &&
      isFirstClick.current
    ) {
      setOpenStatus(false);
    } else {
      isFirstClick.current = true;
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <CsbErrorBoundary>
      <div
        data-testid={`InlineActionModuleButtonsToast-${item.id}`}
        ref={container}
      >
        <Notification
          controlMode="uncontrolled"
          isOpen={openStatus}
          mode="toast"
          type="warning"
          autoCloseTimeout={0}
          hasCloseButton
          heading={capitalizeFirstLetter('warning')}
          onCloseEnd={() => dispatch(removeToast(item.id))}
          key={item.id}
        >
          <span slot="notificationContent">
            <span>{actionText}</span>
          </span>
          <NotificationActions slot="notificationActions">
            <ButtonField mode={'text'} size={'small'}>
              <button
                type="button"
                onClick={handleAction}
                disabled={isLoading}
                data-testid={'ActionBtn'}
              >
                {item?.additionalData?.id === INLINE_ACTION_MODULE_TYPES.stop
                  ? 'Stop'
                  : item?.additionalData?.id ===
                    INLINE_ACTION_MODULE_TYPES.restart
                  ? 'Restart'
                  : 'Remove'}
              </button>
            </ButtonField>
            <ButtonField mode={'text'} size={'small'}>
              <button
                type="button"
                onClick={handleCancel}
                disabled={isLoading}
                data-testid={'CancelBtn'}
              >
                Cancel
              </button>
            </ButtonField>
          </NotificationActions>
        </Notification>
      </div>
    </CsbErrorBoundary>
  );
};
