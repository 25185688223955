import {
  ColDef,
  ColumnApi,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  RowClickedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/shared/agGridColumn';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { ITableContainerProps } from 'interfaces/table.interface';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './ManageModulesTable.module.scss';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import { setSelectedModuleFromRow } from 'app/manageModules/ManageModulesSlice';
import { IModuleRow } from 'interfaces/modules/module.interface';
import CellColorStatus from 'components/CellColorStatus/CellColorStatus';
import CellModuleName from 'components/CellModuleName/CellModuleName';
import CellWithTooltip from 'components/CellWithTooltip/CellWithTooltip';

const defaultColumnDef: ColDef = {
  width: 200,
  sortable: true,
  suppressColumnsToolPanel: true,
  icons: {
    sortAscending: '<span class="wk-icon-arrow-up" aria-hidden="true"/></span>',
    sortDescending:
      '<span class="wk-icon-arrow-down" aria-hidden="true"/></span>',
  },
};

interface IManageModuleTableProps extends ITableContainerProps {
  items: IModuleRow[];
  tenant: string;
}

const gridOptionComponents = {
  cellColorStatus: CellColorStatus,
  cellModuleName: CellModuleName,
  cellWithTooltip: CellWithTooltip,
};

const ManageModulesTable = (props: IManageModuleTableProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const gridApiRef = useRef<GridApi | null>(null);
  const gridColumnApiRef = useRef<ColumnApi | null>(null);
  const [loading, setLoading] = useState(true);

  const updateLoadingOverlay = () => {
    if (gridApiRef.current) {
      if (loading) {
        gridApiRef.current.showLoadingOverlay();
      } else {
        gridApiRef.current.hideOverlay();
      }
    }
  };

  useEffect(() => {
    updateLoadingOverlay();
  }, [loading]);

  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    if (gridApiRef.current) {
      adjustColumnsWidth();
      setLoading(false);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;
    updateLoadingOverlay();
  };

  const onGridSizeChanged = (event: GridSizeChangedEvent) => {
    gridApiRef.current?.sizeColumnsToFit();
  };

  const adjustColumnsWidth = () => {
    if (gridColumnApiRef) {
      gridColumnApiRef.current?.autoSizeAllColumns();
      gridApiRef.current?.sizeColumnsToFit();
    }
  };

  const onRowClicked = (event: RowClickedEvent<any>) => {
    const clickedModule = event.data as IModuleRow;
    dispatch(
      setSelectedModuleFromRow({
        id: clickedModule.id,
        slave: props.tenant,
      })
    );
  };

  const getRowId = useCallback((params) => params.data.id, []);

  const gridOptions: GridOptions = {
    components: gridOptionComponents,
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: false,
    suppressRowVirtualisation: false,
    rowBuffer: 20,
    localeText: {
      search: 'Start typing...',
    },
    defaultColDef: defaultColumnDef,
    headerHeight: 40,
    rowHeight: 40,
    domLayout: 'normal',
    loadingOverlayComponent: TableOverlayComponent,
    loadingOverlayComponentParams: { page: props.page, style: 'default' },
    overlayNoRowsTemplate: '<span>No results</span>',
    animateRows: true,
    onGridReady,
    onGridSizeChanged,
    onRowClicked,
    onFirstDataRendered,
  };

  return (
    <CsbErrorBoundary>
      <div className={style['csb-table']}>
        <div
          className={style['wk-advanced-table-container']}
          data-testid="TableContainer"
        >
          <div
            className={`wk-advanced-table ${style['csb-table__content']} ${
              props.additionalStyles ?? style['wk-striped']
            }`}
          >
            <AgGridReact
              gridOptions={gridOptions}
              rowData={props.items}
              getRowId={getRowId}
              {...props.additionalData}
            >
              {props.columns.map((column) => (
                <AgGridColumn {...column} key={column.field} />
              ))}
            </AgGridReact>
          </div>
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

export default ManageModulesTable;
