import { ButtonField, Tooltip, TooltipBody } from '@wk/components-react16';
import {
  usePostResubmitMutation,
  usePostStopMutation,
} from 'api/orchestrations/orchestrationApi';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { ORCHESTRATION_STATUSES } from 'utils/common-constants';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import style from './TableActionsRight.module.scss';
import {
  selectFilterStatus,
  selectSelectedOrchestrations,
} from 'app/dashboard/DashboardSlice';
import {
  consoleErrorMessage,
  hasPermission,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import { selectUserPermissions } from 'app/auth/AuthSlice';
import { fetchAllOrchestrations } from 'api/orchestrations/orchestrationsThunk';

const TableActionsRight = () => {
  const selectedOrchestrationsState = useAppSelector(
    selectSelectedOrchestrations
  );
  const userPermission = useAppSelector(selectUserPermissions);
  const [enableResubmit, setEnableResubmit] = useState<boolean>(false);
  const [enableStop, setEnableStop] = useState<boolean>(false);
  const [tooltipResubmit, setTooltipResubmit] = useState<string>('');
  const [tooltipStop, setTooltipStop] = useState<string>('');
  const [reSubmitOrchestration] = usePostResubmitMutation();
  const [stopOrchestration] = usePostStopMutation();
  const [isResubmitHovered, setIsResubmitHovered] = useState<boolean>(false);
  const [isStopHovered, setIsStopHovered] = useState<boolean>(false);
  const filterInfo = useAppSelector((state) => selectFilterStatus(state));
  const dispatch = useAppDispatch();

  useEffect(() => {
    const length = selectedOrchestrationsState.length;
    let allResubmit = true;
    const allStop = hasPermission(userPermission, ['ORCHESTRATION_STOP']);

    selectedOrchestrationsState.forEach((orchestration) => {
      allResubmit = allResubmit && (orchestration.supportResubmit || false);
    });

    if (length === 0) {
      setEnableResubmit(false);
      setTooltipResubmit('Resubmit orchestrations');
      setEnableStop(false);
      setTooltipStop('Stop active orchestrations');
      return;
    }

    if (allResubmit) {
      setEnableResubmit(true);
      setTooltipResubmit('Resubmit orchestrations');
    } else {
      setEnableResubmit(false);
      if (length > 1) {
        setTooltipResubmit(
          'Resubmit orchestration may be valid for one or more selected orchestrations but not for all selected orchestrations'
        );
      } else {
        setTooltipResubmit('Resubmit orchestration');
      }
    }

    if (allStop) {
      setEnableStop(true);
    }
  }, [userPermission, selectedOrchestrationsState]);

  const onResubmitClick = (): void => {
    const calls = selectedOrchestrationsState.map((orchestration) =>
      reSubmitOrchestration(orchestration.id)
    );
    Promise.all(calls).then(() => {});
  };

  const onStopClick = (): void => {
    const calls = selectedOrchestrationsState
      .filter((orchestration) => {
        return (
          orchestration.status === ORCHESTRATION_STATUSES.IN_PROGRESS ||
          orchestration.status === ORCHESTRATION_STATUSES.PENDING
        );
      })
      .map(
        async (orchestration) =>
          new Promise(async (resolve) => {
            try {
              await stopOrchestration(orchestration.id).unwrap();
              resolve(undefined);
            } catch (err) {
              const message = consoleErrorMessage(err) ?? 'Error stopping job';
              showToastAndClose(
                'error',
                'informationToast',
                (...args) => {},
                dispatch,
                {
                  text: message,
                }
              );
              resolve(undefined);
            }
          })
      );
    Promise.all(calls)
      .then(() => dispatch(fetchAllOrchestrations(filterInfo || {})))
      .finally(() => {});
  };

  return (
    <CsbErrorBoundary>
      <>
        <div
          className={style['table-actions-right']}
          data-testid="TableActionsRight"
        >
          <ButtonField size={'small'}>
            <div
              id="stop-orchestrations"
              onMouseEnter={() => setIsStopHovered(true)}
              onMouseLeave={() => setIsStopHovered(false)}
            >
              <button
                type="button"
                className={style['table-actions-right__stop']}
                disabled={
                  !enableStop ||
                  selectedOrchestrationsState.some(
                    (o) =>
                      o.status !== ORCHESTRATION_STATUSES.IN_PROGRESS &&
                      o.status !== ORCHESTRATION_STATUSES.PENDING
                  )
                }
                onClick={onStopClick}
                data-testid="StopButton"
              >
                Stop orchestration
              </button>
            </div>
          </ButtonField>
          <Tooltip
            position="top"
            targetSelector="#stop-orchestrations"
            controlMode="controlled"
            isOpen={isStopHovered}
          >
            <TooltipBody slot="tooltipBody">{tooltipStop}</TooltipBody>
          </Tooltip>
          <ButtonField mode={'ghost'} size={'small'}>
            <div
              id="resubmit-orchestrations"
              onMouseEnter={() => setIsResubmitHovered(true)}
              onMouseLeave={() => setIsResubmitHovered(false)}
            >
              <button
                type="button"
                className={style['table-actions-right__resubmit']}
                disabled={!enableResubmit}
                data-testid="ResubmitButton"
                onClick={onResubmitClick}
              >
                Resubmit{' '}
              </button>
            </div>
          </ButtonField>
          {tooltipResubmit && (
            <Tooltip
              position="top"
              targetSelector="#resubmit-orchestrations"
              mode="description"
              size="auto"
              controlMode="controlled"
              isOpen={isResubmitHovered}
            >
              <TooltipBody slot="tooltipBody">
                <div style={{ maxWidth: '180px' }}>{tooltipResubmit}</div>
              </TooltipBody>
            </Tooltip>
          )}
        </div>
      </>
    </CsbErrorBoundary>
  );
};

export default TableActionsRight;
