import { IOrchestrationFullStep } from 'interfaces/orchestration.interface';
import { IServerWorkflow } from 'interfaces/workflow.interface';
import {
  IWorkflow,
  RUN_ORCHESTRARIONS_STATUS,
} from 'interfaces/runOrchestration/workflow-row';

export const mapWorkflowToTable = (workflow: IServerWorkflow): IWorkflow => {
  const steps = extractStepsFromWorkflow(workflow);
  return {
    id: workflow.workflowId,
    name: workflow.name || '',
    status: extractWorkflowStatus(workflow.disabled),
    worker: workflow.tenant || '',
    throttleRate: workflow.throttleRate?.toString() || '0',
    isProduct: false,
    products: workflow.productGroup?.products ?? null,
    steps,
    description: workflow.description,
    supportsPrioritization: workflow.supportsPrioritization,
    hasDynamicParameters: checkForDynamicParameters(steps),
  };
};

export const extractStepsFromWorkflow = (obj: IServerWorkflow) => {
  const steps: IOrchestrationFullStep[] = [];

  function extractSteps(stepGroup: any) {
    if (!stepGroup || !stepGroup.steps) {
      return;
    }

    for (const step of stepGroup.steps) {
      steps.push(step);

      if (step.nestedStepGroup) {
        extractSteps(step.nestedStepGroup);
      }
    }

    if (stepGroup.nextStepGroup) {
      extractSteps(stepGroup.nextStepGroup);
    }
  }

  extractSteps(obj.nextStepGroup);

  return steps;
};

const checkForDynamicParameters = (steps: any) => {
  let hasDynamicParameters = false;
  steps?.forEach((step: any) => {
    step?.paramGroups?.forEach((paramGroup: any) => {
      paramGroup?.parameters?.forEach((parameter: any) => {
        if (parameter.isDynamic) {
          hasDynamicParameters = true;
        }
      });
    });
  });
  return hasDynamicParameters;
};

export const extractWorkflowStatus = (
  status: boolean
): RUN_ORCHESTRARIONS_STATUS => {
  return status === true
    ? RUN_ORCHESTRARIONS_STATUS.INACTIVE
    : RUN_ORCHESTRARIONS_STATUS.ACTIVE;
};
