import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import style from './CellWorkflowName.module.scss';
import { RadioField } from '@wk/components-react16/dist/radio-field/radio-field';
import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import {
  IWorkflowProduct,
  IWorkflow,
} from 'interfaces/runOrchestration/workflow-row';
import {
  removeWorkflowProducts,
  addWorkflowProducts,
  setSelectedWorkflow,
  selectSelectedWorkflow,
  selectFilterQuery,
  selectWorkflowsIdsAndType,
} from 'app/runOrchestrations/RunOrchestrationsSlice';
import { Tooltip } from '@wk/components-react16/dist/tooltip/tooltip';
import { TooltipBody } from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectConnectionStatus } from 'app/auth/AuthSlice';
import { SSE_STATUS } from 'utils/common-constants';
import useWkTooltipOutsideComponent from 'utils/hooks/wk-tooltips/useWkTooltipOutsideComponent';

interface ICellWorkflowName {
  data: Partial<IWorkflowProduct>;
}

const CellWorkflowName = (props: ICellWorkflowName) => {
  const rowData: Partial<IWorkflowProduct> = props.data;
  const dispatch = useAppDispatch();
  const isFiltering = useAppSelector(selectFilterQuery);
  const [isChevronOpen, setIsChevronOpen] = useState<any>(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const products = rowData.products ?? [];
  const name = rowData.name;
  const cellRef = useRef<MutableRefObject<any> | any>();
  const selectedWorkflow = useAppSelector(selectSelectedWorkflow);
  const selectWorkflowsId = useAppSelector(selectWorkflowsIdsAndType);
  const childsAlreadyAdded = useRef(false);
  const connectionStatusState = useAppSelector(selectConnectionStatus);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const spanRef = useRef<HTMLSpanElement>(null);
  const { moveWkTooltipToOutsideElement } = useWkTooltipOutsideComponent(
    `workflow-name-${rowData.id}-tooltip`
  );

  useEffect(() => {
    if (isTooltipOpen && isTruncated) {
      moveWkTooltipToOutsideElement();
    }
  }, [isTooltipOpen]);

  useEffect(() => {
    if (spanRef.current) {
      setIsTruncated(spanRef.current.scrollWidth > spanRef.current.clientWidth);
    }
    const currentIndex = selectWorkflowsId.findIndex(
      (item) => item.id === rowData.id
    );
    const nextItem = selectWorkflowsId[currentIndex + 1];

    if (nextItem && nextItem.isProduct) {
      setIsChevronOpen(true);
      childsAlreadyAdded.current = true;
    }
  }, [rowData]);

  useEffect(() => {
    if (isChevronOpen === true && !childsAlreadyAdded.current) {
      childsAlreadyAdded.current = true;
      dispatch(addWorkflowProducts(rowData as IWorkflow));
    } else if (isChevronOpen === false && childsAlreadyAdded.current) {
      childsAlreadyAdded.current = false;
      dispatch(removeWorkflowProducts({ id: rowData.id, products }));
    }
    if (!rowData.products || rowData.products.length === 0) {
      setIsChevronOpen(false);
      childsAlreadyAdded.current = false;
    }
  }, [isChevronOpen, rowData]);

  useEffect(() => {
    if (isFiltering != null && isFiltering !== '') {
      if (isChevronOpen) {
        childsAlreadyAdded.current = false;
      } else {
        childsAlreadyAdded.current = true;
      }
      setIsChevronOpen(true);
    } else if (isFiltering === '') {
      setIsChevronOpen(false);
      childsAlreadyAdded.current = false;
    }
  }, [isFiltering]);

  useEffect(() => {
    if ((selectedWorkflow as IWorkflowProduct)?.parentId !== rowData.id) {
      return;
    }
    if (
      rowData.products?.find(
        (product) =>
          product.productId ===
          (selectedWorkflow as IWorkflowProduct)?.productId
      )
    ) {
      setIsChevronOpen(true);
    }
  }, [selectedWorkflow]);

  useEffect(() => {
    if (isSelected()) {
      cellRef.current?.parentElement?.parentElement?.classList.add(
        'csb-focus-row'
      );
    } else {
      cellRef.current?.parentElement?.parentElement?.classList.remove(
        'csb-focus-row'
      );
    }
  }, [selectedWorkflow]);

  useEffect(() => {
    if (connectionStatusState === SSE_STATUS.RESTORED) {
      childsAlreadyAdded.current = false;
    }
  }, [connectionStatusState]);

  const selectOrchestration = () => {
    if (selectedWorkflow?.id !== rowData.id) {
      dispatch(setSelectedWorkflow(rowData as any));
    }
  };

  const isSelected = () => {
    return (
      selectedWorkflow?.id === rowData.id ||
      (rowData.productId !== undefined &&
        (selectedWorkflow as IWorkflowProduct)?.productId === rowData.productId)
    );
  };

  const handleClick = () => {
    if (products?.length > 0) {
      setIsChevronOpen((isChevronOpen: any) =>
        isChevronOpen === null ? true : !isChevronOpen
      );
    }
  };

  return (
    <CsbErrorBoundary>
      <div
        className={style['cell-container']}
        data-testid="CellWorkflowName"
        id={`workflow-name-${rowData.id}-cell`}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        ref={cellRef}
        onClick={() => handleClick()}
        key={rowData.id}
      >
        {products?.length > 0 && (
          <div className={style['cell-chevron']}>
            <div>
              {isChevronOpen ? (
                <Iconsvg name="chevron-down"></Iconsvg>
              ) : (
                <Iconsvg name="chevron-right"></Iconsvg>
              )}
            </div>
          </div>
        )}
        <div
          className={
            rowData.isProduct
              ? style['cell-product']
              : style['cell-orchestration']
          }
        >
          <RadioField>
            <input
              type="radio"
              name="cellNameRadioBtn"
              onChange={() => selectOrchestration()}
              checked={isSelected()}
            />
          </RadioField>
        </div>
        <span className={style['cell-name']} ref={spanRef}>
          {name}
        </span>
        {name && isTruncated && (
          <Tooltip
            position="right"
            targetSelector={`#workflow-name-${rowData.id}-cell`}
            id={`workflow-name-${rowData.id}-tooltip`}
            controlMode="uncontrolled"
            isOpen={isTooltipOpen}
          >
            <TooltipBody slot="tooltipBody">
              <span data-testid="CellStatusTooltip">
                <span
                  id={`name-${rowData.id}`}
                  style={{ whiteSpace: 'break-spaces' }}
                >
                  {name}
                </span>
              </span>
            </TooltipBody>
          </Tooltip>
        )}
      </div>
    </CsbErrorBoundary>
  );
};

export default CellWorkflowName;
