import { TabDropdownItem } from '@wk/components-react16/dist/tab-dropdown-item/tab-dropdown-item';
import { TabHeader } from '@wk/components-react16/dist/tab-header/tab-header';
import { TabPanel } from '@wk/components-react16/dist/tab-panel/tab-panel';
import { Tabs } from '@wk/components-react16/dist/tabs/tabs';
import style from './ManageModulesTabs.module.scss';
import { Tooltip } from '@wk/components-react16/dist/tooltip/tooltip';
import { TooltipBody } from '@wk/components-react16/dist/tooltip-body/tooltip-body';
import { useState } from 'react';
import { ModuleTabSection } from './ModuleTabSection/ModuleTabSection';

export const ManageModulesTabs = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  return (
    <section className={style['container']} data-testid="ManageModulesTabs">
      <Tabs
        mode="classic"
        selectedTab={selectedTab}
        onUserRequest={(event: number) => setSelectedTab(event)}
        controlMode="controlled"
      >
        <TabHeader slot="tabHeader">
          <div id="module-tab">Module</div>
        </TabHeader>
        <TabHeader slot="tabHeader">
          <div id="tenant-tab">Tenant</div>
        </TabHeader>
        <TabDropdownItem slot="tabDropdownItem">Module</TabDropdownItem>
        <TabDropdownItem slot="tabDropdownItem">Tenant</TabDropdownItem>
        <TabPanel slot="tabPanel">
          {selectedTab === 0 && <ModuleTabSection type="module-list" />}
        </TabPanel>
        <TabPanel slot="tabPanel">
          {selectedTab === 1 && <ModuleTabSection type="tenant-list" />}
        </TabPanel>
      </Tabs>
      <Tooltip
        targetSelector="#module-tab"
        controlMode="uncontrolled"
        position="top-right"
      >
        <TooltipBody slot="tooltipBody">
          Search for a specific module
        </TooltipBody>
      </Tooltip>

      <Tooltip targetSelector="#tenant-tab" controlMode="uncontrolled">
        <TooltipBody slot="tooltipBody">
          Search for a specific tenant
        </TooltipBody>
      </Tooltip>
    </section>
  );
};
