import { ButtonField } from '@wk/components-react16/dist/button-field/button-field';
import { ButtonGroupItem } from '@wk/components-react16/dist/button-group-item/button-group-item';
import { ButtonGroup } from '@wk/components-react16/dist/button-group/button-group';
import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import { TooltipBody } from '@wk/components-react16/dist/tooltip-body/tooltip-body';
import { Tooltip } from '@wk/components-react16/dist/tooltip/tooltip';
import { selectUserPermissions } from 'app/auth/AuthSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { USER_PERMISSIONS } from 'utils/common-constants';
import {
  consoleErrorMessage,
  hasPermission,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import { useTooltipControlled } from 'utils/hooks/useTooltipControlled';
import style from './ManageModuleButtons.module.scss';
import { IModuleServer } from 'interfaces/modules/module.interface';
import { selectSelectedModule } from 'app/manageModules/ManageModulesSlice';
import { useEffect, useState } from 'react';
import { addToast, selectNotifications } from 'app/toast/toastSlice';
import { INLINE_ACTION_MODULE_TYPES } from 'components/Toasts/InlineActionModuleButtonsToast/InlineActionModuleButtonsToast';
import { useStartAllModulesMutation } from 'api/manageModules/modulesApi';

const START = 'start';
const STOP = 'stop';
const RESTART = 'restart';
const REMOVE = 'remove';
const UPDATE = 'update';
const CREATE_NEW = 'create-new';
const DEPLOY_NEW = 'deploy-new';

const buttonsData = [
  {
    id: START,
    tooltip: `Start a module on all tenant's workers`,
    text: 'Start',
    icon: 'play',
  },
  {
    id: STOP,
    tooltip: `Stop a module on all tenant's workers`,
    text: 'Stop',
    icon: 'stop',
  },
  {
    id: RESTART,
    tooltip: `Restart a module on all tenant's workers`,
    text: 'Restart',
    icon: 'refresh',
  },
  {
    id: REMOVE,
    tooltip: `Remove a module from all tenant's workers`,
    text: 'Remove',
    icon: 'minus',
  },
  {
    id: UPDATE,
    tooltip: `Update a module`,
    text: 'Update',
    icon: 'arrow-up',
  },
  {
    id: CREATE_NEW,
    tooltip: `Create a new module`,
    text: 'Create new',
    icon: 'plus',
  },
  {
    id: DEPLOY_NEW,
    tooltip: `Deploy a new module`,
    text: 'Deploy new',
    icon: 'bolt',
  },
];

export const ManageModuleButtons = () => {
  const userPermission = useAppSelector(selectUserPermissions);
  const selectedModule: IModuleServer = useAppSelector(selectSelectedModule);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { handleTooltip, getValue } = useTooltipControlled(
    buttonsData.map((button) => button.id)
  );
  const [wasStopModuleToastPresent, setWasStopModuleToastPresent] =
    useState(false);
  const toastNotifications = useAppSelector(selectNotifications);
  const [startAllModuleInstances] = useStartAllModulesMutation();

  const handleClick = async (buttonType: string) => {
    setLoading(true);
    if (buttonType === START) {
      try {
        await startAllModuleInstances({
          moduleId: selectedModule.id,
          slave: selectedModule.slave,
        }).unwrap();
        showToastAndClose(
          'success',
          'informationToast',
          (...args) => {},
          dispatch,
          {
            text: `Your module has been started. It may take a while for the status to be updated.`,
          }
        );
        setLoading(false);
      } catch (error) {
        console.error('Error while starting all modules', error);
        showToastAndClose(
          'error',
          'informationToast',
          (...args) => {},
          dispatch,
          {
            text:
              consoleErrorMessage(error) ??
              'Unable to start module: An unknown error occurred.',
          }
        );
        setLoading(false);
      }
    } else if (buttonType === STOP) {
      dispatch(
        addToast({
          id: 'myToastId',
          type: 'inlineActionModuleButtonsToast',
          status: 'warning',
          additionalData: {
            id: INLINE_ACTION_MODULE_TYPES.stop,
          },
        })
      );
    } else if (buttonType === RESTART) {
      dispatch(
        addToast({
          id: 'myToastId',
          type: 'inlineActionModuleButtonsToast',
          status: 'warning',
          additionalData: {
            id: INLINE_ACTION_MODULE_TYPES.restart,
          },
        })
      );
    } else if (buttonType === REMOVE) {
      dispatch(
        addToast({
          id: 'myToastId',
          type: 'inlineActionModuleButtonsToast',
          status: 'warning',
          additionalData: {
            id: INLINE_ACTION_MODULE_TYPES.remove,
          },
        })
      );
    } else {
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    const inlineActionToast = toastNotifications.find(
      (toast) => toast.type === 'inlineActionModuleButtonsToast'
    );

    if (inlineActionToast) {
      setLoading(true);
      setWasStopModuleToastPresent(true);
    } else if (wasStopModuleToastPresent) {
      setLoading(false);
      setWasStopModuleToastPresent(false);
    }
  }, [toastNotifications]);

  return (
    <section
      className={style['buttons']}
      data-testid="ManageModuleButtons"
      id="manageModulesBannerButtons"
    >
      <ButtonGroup mode="static">
        {buttonsData.slice(0, 5).map((button) => (
          <ButtonGroupItem slot="buttonGroupItem" key={button.id}>
            <ButtonField mode="ghost" iconPosition="left">
              <div
                onMouseEnter={() => handleTooltip(button.id, true)}
                onMouseLeave={() => handleTooltip(button.id, false)}
              >
                <button
                  type="button"
                  onClick={() => handleClick(button.id)}
                  id={button.id}
                  data-testid={`${button.id}Btn`}
                  disabled={checkIfDisabled(
                    button.id,
                    loading,
                    userPermission,
                    selectedModule
                  )}
                >
                  <Iconsvg name={button.icon} />
                  {button.text}
                </button>
              </div>
            </ButtonField>
          </ButtonGroupItem>
        ))}
      </ButtonGroup>
      <ButtonGroup mode="static">
        {buttonsData.slice(5).map((button) => (
          <ButtonGroupItem slot="buttonGroupItem" key={button.id}>
            <ButtonField
              mode={button.id === DEPLOY_NEW ? 'default' : 'ghost'}
              iconPosition="left"
            >
              <div
                onMouseEnter={() => handleTooltip(button.id, true)}
                onMouseLeave={() => handleTooltip(button.id, false)}
              >
                <button
                  type="button"
                  onClick={() => handleClick(button.id)}
                  id={button.id}
                  data-testid={`${button.id}Btn`}
                  disabled={checkIfDisabled(
                    button.id,
                    loading,
                    userPermission,
                    selectedModule
                  )}
                >
                  <Iconsvg name={button.icon} />
                  {button.text}
                </button>
              </div>
            </ButtonField>
          </ButtonGroupItem>
        ))}
      </ButtonGroup>
      <Tooltips getValue={getValue} />
    </section>
  );
};

const Tooltips = ({
  getValue,
}: {
  getValue: (value: string) => true | undefined;
}) => {
  return (
    <>
      {buttonsData.map((item: any) => (
        <Tooltip
          position="bottom"
          targetSelector={`#${item.id}`}
          controlMode="controlled"
          isOpen={getValue(item.id)}
          key={item.id}
        >
          <TooltipBody slot="tooltipBody">{item.tooltip}</TooltipBody>
        </Tooltip>
      ))}
    </>
  );
};

const checkIfDisabled = (
  buttonType: string,
  isLoading: boolean,
  userPermission: any,
  selectedModule: IModuleServer | undefined
) => {
  let isDisabled = false;

  if (buttonType === START) {
    isDisabled =
      isLoading ||
      selectedModule === null ||
      selectedModule?.instances?.find(
        (instance: any) => instance.consumersCount > 0
      ) !== undefined ||
      !hasPermission(userPermission, [USER_PERMISSIONS.MODULE_START]);
  } else if (buttonType === STOP) {
    isDisabled =
      isLoading ||
      selectedModule === null ||
      selectedModule?.instances?.find(
        (instance: any) => instance.consumersCount === 0
      ) !== undefined ||
      !hasPermission(userPermission, [USER_PERMISSIONS.MODULE_STOP]);
  } else if (buttonType === RESTART) {
    isDisabled =
      isLoading ||
      selectedModule === null ||
      selectedModule?.instances?.find(
        (instance: any) => instance.consumersCount === 0
      ) !== undefined ||
      !hasPermission(userPermission, [USER_PERMISSIONS.MODULE_RESTART]);
  } else if (buttonType === REMOVE) {
    isDisabled =
      isLoading ||
      selectedModule === null ||
      !hasPermission(userPermission, [USER_PERMISSIONS.MODULE_REMOVE]);
  } else if (buttonType === UPDATE) {
    isDisabled =
      isLoading ||
      !hasPermission(userPermission, [USER_PERMISSIONS.MODULE_UPDATE]);
  } else if (buttonType === CREATE_NEW) {
    isDisabled =
      isLoading ||
      !hasPermission(userPermission, [USER_PERMISSIONS.MODULE_CREATE_PAGE]);
  } else if (buttonType === DEPLOY_NEW) {
    isDisabled =
      isLoading ||
      !hasPermission(userPermission, [USER_PERMISSIONS.MODULE_DEPLOY]);
  }
  return isDisabled;
};
