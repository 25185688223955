import { SearchField } from '@wk/components-react16/dist/search-field/search-field';
import style from './ModuleTabSection.module.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectAvailableModules,
  selectModulesLoading,
  selectSelectedModule,
  setSelectedModuleFromRow,
} from 'app/manageModules/ManageModulesSlice';
import {
  IAvailableModuleServer,
  IModuleServer,
} from 'interfaces/modules/module.interface';
import { MANAGE_MODULES_TABLE_COLUMNS } from 'pages/ManageModules/ManageModules.constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import { moduleToTable } from 'dto/modules/moduleToTable';
import ManageModulesDetailedInformation from 'components/ManageModules/ManageModulesDetailedInformation/ManageModulesDetailedInformation';
import { checkAndScrollToItem } from 'utils/commonFunctions/CommonFunctions';
import { debounce } from '@wk/components-react16';
import ManageModulesTable from 'components/TableContainer/ManageModulesTable/ManageModulesTable';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';

interface IModuleTabSection {
  type: 'module-list' | 'tenant-list';
}

export const ModuleTabSection = ({ type }: IModuleTabSection) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredModules, setFilteredModules] =
    useState<IAvailableModuleServer>({});
  const dataTestId =
    type === 'module-list' ? 'ModuleTabSection' : 'TenantTabSection';
  const searchLabel =
    type === 'module-list' ? 'Search for module' : 'Search for tenant';
  const modules: IAvailableModuleServer = useAppSelector(
    selectAvailableModules
  );
  const dispatch = useAppDispatch();
  const [queryParams, setQueryParams] = useState(
    new URLSearchParams(window.location.search)
  );
  const selectedModule: IModuleServer = useAppSelector(selectSelectedModule);
  const loading: boolean = useAppSelector(selectModulesLoading);

  useEffect(() => {
    if (queryParams && modules && Object.keys(modules).length !== 0) {
      const querySlave = queryParams.get('slave');
      const querId = queryParams.get('id');
      if (querySlave && querId) {
        window.history.replaceState(null, '', '/manage-modules');
        setQueryParams(new URLSearchParams(window.location.search));
        dispatch(setSelectedModuleFromRow({ id: +querId, slave: querySlave }));
        checkAndScrollToItem(`#module-name-${querId}-cell`);
      }
    }
  }, [modules, queryParams]);

  useEffect(() => {
    const filtered = filterModules((modules as any) ?? {}, searchQuery);
    setFilteredModules(filtered);
  }, [searchQuery, modules]);

  const filterModules = useCallback(
    (modules: IAvailableModuleServer, query: string) => {
      if (!query) {
        return modules;
      }

      const lowerCaseQuery = query.toLowerCase();
      return Object.entries(modules).reduce((acc, [key, value]) => {
        const filteredModules = value.filter((module) =>
          module.name?.toLowerCase()?.includes(lowerCaseQuery)
        );
        if (filteredModules?.length > 0) {
          acc[key] = filteredModules;
        }
        return acc;
      }, {} as IAvailableModuleServer);
    },
    []
  );

  const handleSearch = useCallback(
    debounce((query: string) => {
      setSearchQuery(query);
    }, 300),
    []
  );

  return (
    <div data-testid={dataTestId}>
      <section className={style['search-bar']}>
        <SearchField>
          <input
            type="search"
            id="search"
            name="search"
            data-testid="SearchInput"
            placeholder={searchLabel}
            ref={null}
            onChange={(event) => handleSearch(event.target.value)}
          />
        </SearchField>
      </section>
      <div className={style['tab-container']}>
        <section className={style['tenants-container']}>
          {loading ? (
            <div className={style['loader']}>
              <TableOverlayComponent
                page={PAGE_OPTIONS.manageModules}
                style="fixed"
              />
            </div>
          ) : (
            Object.entries(filteredModules).map(([key, value]) => (
              <TableSection name={key} modules={value} />
            ))
          )}
        </section>
        {selectedModule && (
          <ManageModulesDetailedInformation selectedModule={selectedModule} />
        )}
      </div>
    </div>
  );
};

const TableSection = ({
  name,
  modules,
}: {
  name: string;
  modules: IModuleServer[];
}) => {
  const rowItems = useMemo(() => {
    const sortedModules = Object.values(modules)
      .flat()
      .sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name);
        if (nameComparison !== 0) {
          return nameComparison;
        }
        const versionA = a.version?.split('.')?.map(Number);
        const versionB = b.version?.split('.')?.map(Number);
        for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
          const numA = versionA?.[i] || 0;
          const numB = versionB?.[i] || 0;
          if (numA !== numB) {
            return numA - numB;
          }
        }
        return 0;
      });
    return moduleToTable(sortedModules);
  }, [modules]);

  return (
    <div className={style['table-container']}>
      <span className={style['tenant-title']}>Tenant: {name}</span>
      <ManageModulesTable
        items={rowItems}
        columns={MANAGE_MODULES_TABLE_COLUMNS}
        onGridReadyAdditionalMethods={[]}
        page={PAGE_OPTIONS.manageModules}
        tenant={name}
      />
    </div>
  );
};
