import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './ManageModulesBanner.module.scss';
import { ManageModuleButtons } from '../ManageModuleButtons/ManageModuleButtons';

export const ManageModulesBanner = () => {
  return (
    <CsbErrorBoundary>
      <section className={style['container']} data-testid="ManageModulesBanner">
        <span className={style['title-row']}>Installed Modules</span>
        <ManageModuleButtons />
      </section>
    </CsbErrorBoundary>
  );
};
